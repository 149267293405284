import React, { Fragment } from "react";
import AboutHeader from "../components/sectionHeader/AboutHeader";
import WhoWeAre from "../components/sectionHeader/WhoWeAre";
import BestOurFeatures from "../components/sectionHeader/BestOurFeatures";
import OurStaff from "../components/sectionOurStaff/OurStaff";

const About = (props) => {
  return (
    <Fragment>
      <AboutHeader />
      <WhoWeAre />
      <BestOurFeatures />
      <OurStaff />
    </Fragment>
  );
};

export default About;
