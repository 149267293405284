import React, { Fragment } from "react";
import styles from "./TrendingSongs.module.css";
import TrendingSongs from "./TrendingSongs";

const TrendingSongsList = (props) => {
  return (
    <Fragment>
      <section>
        <div className={styles.title}>
          <h3>TRENDING SONGs</h3>
        </div>

        <div className={styles["sub-title"]}>
          <h1>Best of our artist</h1>
        </div>
        <div className={styles["grid-3-cols"]}>
          {props.songs.map((song) => (
            <TrendingSongs
              key={song._id}
              id={song._id}
              user={song.user}
              image={song.image}
              audio={song.audio}
              musicArt={song.musicArt}
              // createdAt={song.createdAt}
              title={song.title}
              //   description={song.description}
            />
          ))}
        </div>
        <div className={styles.line}></div>
      </section>
    </Fragment>
  );
};

export default TrendingSongsList;
