// import React from "react";
import styles from "./AboutMe.module.css";

const AboutMe = (props) => {
  return (
    <div className={styles["about-artist"]}>
      <div className={styles["about-me"]}>
        <h1>Über Künstler</h1>
        <p>{props.aboutMe}</p>
      </div>
      <div className={styles["my-project"]}>
        <h1>Meine Projekte</h1>
        <p>{props.myProjects}</p>
      </div>
    </div>
  );
};

export default AboutMe;
