import React, { useEffect, useState } from "react";
import SongsList from "./SongsList";
import ClipLoader from "react-spinners/ClipLoader";
import styles from "./Songs.module.css";

const FetchingSongs = (props) => {
  const [songs, setSongs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    setIsLoading(true);
    try {
      (async () => {
        const res = await fetch("https://hanf.herokuapp.com/api/v1/arts");
        const data = await res.json();
        setSongs(data.data.arts);
        setIsLoading(false);
      })();
    } catch (err) {
      setIsLoading(false);
      setError(err.message);
    }
  }, []);

  const songDeletedHandler = (deleteSongId) => {
    setSongs((prevSong) =>
      prevSong.filter((songs) => songs._id !== deleteSongId)
    );
  };

  if (isLoading) {
    return (
      <div className={styles["loader-container"]}>
        <ClipLoader color={"#fff"} size={150} />
      </div>
    );
  }
  return (
    <React.Fragment>
      <SongsList songs={songs} onDeleteSong={songDeletedHandler} />
      {songs.length === 0 && (
        <h1>Keine Liede, du kannst ein neues erstellen</h1>
      )}
    </React.Fragment>
  );
};

export default FetchingSongs;
