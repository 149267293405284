// import React from "react";
import OurEventsItem from "./OurEventsItem";
import styles from "./OurEventsItem.module.css";

const OurEventList = (props) => {
  return (
    <section>
      <p className={styles.title}>
        <strong>Stay tuned: Unsere nächsten Events</strong>
      </p>

      <div className={styles["sub-title"]}>
        <h1>Wir bieten eine Bühne für Newcomer</h1>
      </div>
      <div className={styles["grid-3-cols"]}>
        {props.events.map((event) => (
          <OurEventsItem
            key={event._id}
            id={event._id}
            title={event.title}
            subTitle={event.subTitle}
            image={event.image}
            date={event.date}
          />
        ))}
      </div>
      <div className={styles.line}></div>
    </section>
  );
};

export default OurEventList;
