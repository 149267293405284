import { Fragment } from "react";
import { AiFillCalendar } from "react-icons/ai";
import { MdOutlineAddLocationAlt } from "react-icons/md";
import styles from "./EventDetail.module.css";

const EventDetail = (props) => {
  return (
    <Fragment>
      <div className={styles["section-wraper"]}>
        <div className={styles["event-page-title"]}>
          <h3>Event Details.</h3>
        </div>

        <div className={styles["event-image-page"]}>
          <img src={`https://hanf.herokuapp.com/${props.image}`} alt="event" />
        </div>
      </div>
      <div className={styles["event-details"]}>
        <div className={styles["event-info"]}>
          <div className={styles["event-info-title"]}>
            <h2>{props.title}</h2>
          </div>
          <div className={styles["event-info-sub-title"]}>
            <h1>{props.subTitle}</h1>
          </div>
          <p className={styles["event-text-teil1"]}>{props.event}</p>
        </div>

        <div className={styles["event-card"]}>
          <div className={styles["event-info-detail"]}>
            <h2>Event Info</h2>
          </div>

          <div className={styles.date}>
            <AiFillCalendar className={styles["date-icon"]} />
            <h2>Datum und Uhrzeit</h2>
            <h1>{props.date}</h1>
            <h1>{props.uhrZeit} uhr</h1>
          </div>

          <div className={styles.address}>
            <MdOutlineAddLocationAlt className={styles["date-icon"]} />
            <h2>Veranstaltungsort</h2>
            <h1>{props.strasseUndHauseNummer}</h1>
            <h1>
              {props.plz} {props.ort}
            </h1>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EventDetail;
