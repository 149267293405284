// import React from "react";
import Posts from "./Posts";

const PostList = (props) => {
  return (
    <section>
      {props.posts.map((post) => (
        <Posts
          key={post._id}
          id={post._id}
          user={post.user}
          post={post.post}
          image={post.image}
          comments={post.comments}
          onDelete={props.onDeletePost}
        />
      ))}
    </section>
  );
};

export default PostList;
