// import React from "react";
import styles from "./Events.module.css";
import { BsCalendarCheck } from "react-icons/bs";
// import Event1 from "./../../../assets/event1.jpg";
import { Link } from "react-router-dom";

const Events = (props) => {
  const date = props.date.toLocaleString("en-US", {
    month: "long",
    day: "2-digit",
  });

  return (
    <section>
      <div className={styles["grid-2"]}>
        <div className={styles.dates}>
          <BsCalendarCheck className={styles["icon-date"]} />
          <div className={styles.date}>{date}</div>
        </div>
        <div className={styles["event-title"]}>
          <h1>{props.title}</h1>
        </div>
        <div className={styles["event-text"]}>
          <p>{props.event}</p>
        </div>
        <Link to={`/events/${props.id}`} className={styles["event-button"]}>
          Mehr Lesen
        </Link>
        <div>
          <img
            src={`https://hanf.herokuapp.com/${props.image}`}
            alt="event"
            className={styles["event-image"]}
          />
        </div>
      </div>
      <div className={styles.line}></div>
    </section>
  );
};

export default Events;
