// import React from "react";
import UpdatePost from "./UpdatePost";
// import styles from "./UpdatePost.module.css";

const UpdatePostList = (props) => {
  return (
    <section>
      <UpdatePost
        key={props.posts._id}
        id={props.posts._id}
        post={props.posts.post}
        image={props.posts.image}
      />
    </section>
  );
};

export default UpdatePostList;
