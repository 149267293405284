import UserHeader from "./UserHeader";

const UserList = (props) => {
  return (
    <section>
      <UserHeader
        key={props.users._id}
        photo={props.users.photo}
        firstname={props.users.firstname}
        aboutMe={props.users.aboutMe}
        myProjects={props.users.myProjects}
        id={props.users._id}
      />
    </section>
  );
};

export default UserList;
