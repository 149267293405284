import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import EventDetailList from "./EventDetailList";
import ClipLoader from "react-spinners/ClipLoader";
import styles from "./EventDetail.module.css";

const FetchingEventDetail = (props) => {
  const params = useParams();
  const { eventDetails } = params;
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    try {
      (async () => {
        const res = await fetch(
          `https://hanf.herokuapp.com/api/v1/events/${eventDetails}`
        );
        const data = await res.json();
        setEvents(data.data.event);
        // console.log(data);
        setIsLoading(false);
      })();
    } catch (err) {
      setIsLoading(false);
    }
  }, [eventDetails]);

  if (isLoading) {
    return (
      <div className={styles["loader-container"]}>
        <ClipLoader color={"#fff"} size={150} />
      </div>
    );
  }

  return (
    <React.Fragment>
      <EventDetailList events={events} />
      {/* {!isLoading && posts.length === 0 && !error && (
          <p>Found no Trending Posts</p>
        )} */}
      {/* {isLoading && <p>Loading...</p>}
        {!isLoading && error && <p>{error}</p>} */}
    </React.Fragment>
  );
};

export default FetchingEventDetail;
