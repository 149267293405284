import React, { useState, useContext } from "react";
import AuthContext from "./../../../store/auth-context";
import { AiOutlineSetting } from "react-icons/ai";
import styles from "./Posts.module.css";
import deleteCommentAtom from "../../../recoil/atom/deleteCommentAtom";
import { useRecoilState } from "recoil";

const Comments = (props) => {
  const authCtx = useContext(AuthContext);
  const [showCommentsSetting, setShowCommentSetting] = useState(false);
  const [, setDeleteComment] = useRecoilState(deleteCommentAtom);

  const showCommnetSettingHandler = () => {
    if (!showCommentsSetting) {
      setShowCommentSetting(true);
    } else {
      setShowCommentSetting(false);
    }
  };

  const deleteCommentHandler = async () => {
    const response = await fetch(
      `https://hanf.herokuapp.com/api/v1/comments/${props.id}`,
      {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + authCtx.token,
        },
      }
    );

    const data = await response;

    if (!response.ok) {
      throw new Error(data.message || "Could not delete a comment.");
    }
    setDeleteComment(data);
  };

  return (
    <div className={styles["post-comments"]}>
      <div className={styles["comments"]}>
        <div className={styles["user-comment-image"]}>
          <img
            src={`https://hanf.herokuapp.com/${props.user.photo}`}
            alt="user"
          />
        </div>
        <div className={styles["comment-username"]}>
          <h1>
            {props.user?.firstname}
            <span>{props.user?.surname}</span>
          </h1>
          {authCtx.id === props.user.id && (
            <div
              className={styles["comment-setting"]}
              onClick={showCommnetSettingHandler}
            >
              <AiOutlineSetting className={styles["comment-setting-icon"]} />
            </div>
          )}
          {showCommentsSetting && (
            <ul className={styles["setting-list"]}>
              <li
                className={styles["setting-list-link"]}
                onClick={deleteCommentHandler}
              >
                Delete
              </li>
            </ul>
          )}
        </div>
      </div>
      <div className={styles["comment"]}>
        <p>{props.comment}</p>
      </div>
    </div>
  );
};

export default Comments;
