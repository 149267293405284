// import React from "react";
import Post from "./Post";
import styles from "./Post.module.css";

const PostList = (props) => {
  return (
    <section>
      <div className={styles["features-title"]}>
        <h3>Trending Posts</h3>
      </div>
      <div className={styles["sub-title"]}>
        <h1>Trending Post Our User</h1>
      </div>

      <div className={styles.card}>
        {props.posts.map((post) => (
          <Post
            key={post._id}
            user={post.user}
            // title={post.title}
            post={post.post}
            image={post.image}
          />
        ))}
      </div>
      <div className={styles.line}></div>
    </section>
  );
};

export default PostList;
