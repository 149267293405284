import React, { useEffect, useState, useContext } from "react";
import AuthContext from "./../../../store/auth-context";
import NavList from "./NavList";
import { useRecoilValue } from "recoil";
import updateProfileImageAtom from "../../../recoil/atom/updateProfileImageAtom";

const FetchingNav = () => {
  const updateImage = useRecoilValue(updateProfileImageAtom);
  const authCtx = useContext(AuthContext);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    {
      authCtx.isLoggedIn &&
        (async () => {
          const res = await fetch(
            `https://hanf.herokuapp.com/api/v1/users/${authCtx.id}`
          );
          const data = await res.json();

          if (!res.ok) {
            throw new Error(
              `This is an HTTP error: The status is ${res.status}`
            );
          }
          setUsers(data.data.user);
        })();
    }
  }, [authCtx.id, authCtx.isLoggedIn, updateImage]);

  return (
    <React.Fragment>
      <NavList users={users} />
    </React.Fragment>
  );
};

export default FetchingNav;
