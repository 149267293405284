import { useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import AuthContext from "../store/auth-context";
import { FaSignInAlt } from "react-icons/fa";
import styles from "./Login.module.css";
import ClipLoader from "react-spinners/ClipLoader";

const Login = () => {
  const authCtx = useContext(AuthContext);
  const history = useHistory();
  const [enteredEmail, setEnteredEmail] = useState();
  const [enteredPassword, setEnteredPassword] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const emailHandler = (event) => {
    setEnteredEmail(event.target.value);
  };
  const passwordHandler = (event) => {
    setEnteredPassword(event.target.value);
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const response = await fetch(
        "https://hanf.herokuapp.com/api/v1/users/login",
        {
          method: "POST",
          body: JSON.stringify({
            email: enteredEmail,
            password: enteredPassword,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      // const currentData = await data;
      // console.log(data.data.user._id);
      if (!response.ok) {
        throw new Error(data.message || "Could not login.");
      }
      authCtx.login(data.token, data.data.user.role, data.data.user._id);
      setIsLoading(false);
      history.replace("/");
    } catch (erro) {
      setIsLoading(false);
      setError(erro.message);
    }
  };

  if (isLoading) {
    return (
      <div className={styles["loader-container"]}>
        <ClipLoader color={"#fff"} size={150} />
      </div>
    );
  }

  return (
    <div className={styles["login-page"]}>
      <div className={styles["login-header"]}>
        <FaSignInAlt className={styles["login-icon"]} />
        <div className={styles["login-title"]}>
          <h2>Anmelden</h2>
        </div>
      </div>
      {error && (
        <div className={styles["errors"]}>
          <div className={styles["error"]}>
            <h3>{error}</h3>
          </div>
        </div>
      )}
      <form onSubmit={submitHandler} className={styles["login-form"]}>
        <div className={styles["login"]}>
          <div>
            <label htmlFor="email">
              <input
                type="email"
                id="email"
                placeholder=" E-Mail-Adresse*"
                required
                onChange={emailHandler}
              />
            </label>
          </div>
          <div>
            <label htmlFor="password">
              <input
                type="password"
                id="password"
                placeholder="Passwort*"
                required
                onChange={passwordHandler}
              />
            </label>
          </div>
          <div className={styles["login-btn"]}>
            <button type="submit">Anmelden</button>
          </div>
          <div className={styles["forget-password"]}>
            <Link to="/forgotPassword">
              <h1>Passwort Vergessen?</h1>
            </Link>
            <Link to="/registrieren">
              <h1>Hast du kein Konto?Registrieren</h1>
            </Link>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Login;
