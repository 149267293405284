import { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import AuthContext from "../store/auth-context";
import { MdPassword } from "react-icons/md";
import styles from "./UpdateMyPassword.module.css";
import ClipLoader from "react-spinners/ClipLoader";

const UpdateMyPassword = () => {
  const authCtx = useContext(AuthContext);
  const history = useHistory();
  const [enteredCurrentPassword, setEnteredCurrentPassword] = useState();
  const [enteredPassword, setEnteredPassword] = useState();
  const [enteredPasswordConfirm, setEnteredPasswordConfirm] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const currentPasswordHandle = (event) => {
    setEnteredCurrentPassword(event.target.value);
  };

  const passwordHandler = (event) => {
    setEnteredPassword(event.target.value);
  };

  const passwordConfirmHandle = (event) => {
    setEnteredPasswordConfirm(event.target.value);
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const response = await fetch(
        "https://hanf.herokuapp.com/api/v1/users/updateMyPassword",
        {
          method: "PATCH",
          body: JSON.stringify({
            passwordCurrent: enteredCurrentPassword,
            password: enteredPassword,
            passwordConfirm: enteredPasswordConfirm,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + authCtx.token,
          },
        }
      );
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Could not login.");
      }

      setIsLoading(false);
      history.replace("/profile");
    } catch (erro) {
      setIsLoading(false);
      setError(erro.message);
    }
  };

  if (isLoading) {
    return (
      <div className="loader-container">
        <ClipLoader color={"#fff"} size={150} />
      </div>
    );
  }

  return (
    <section>
      <div className={styles["login-header"]}>
        <MdPassword className={styles["login-icon"]} />
        <div className={styles["login-title"]}>
          <h2>Update my password</h2>
        </div>
      </div>

      {error && (
        <div className={styles["errors"]}>
          <div className={styles["error"]}>
            <h3>{error}</h3>
          </div>
        </div>
      )}

      <form onSubmit={submitHandler} className={styles["login-form"]}>
        <div className={styles["login"]}>
          <div>
            <div>
              <label htmlFor="currentPassword">
                <input
                  type="password"
                  id="currentPassword"
                  placeholder="Current password*"
                  required
                  onChange={currentPasswordHandle}
                />
              </label>
            </div>
            <div>
              <label htmlFor="password">
                <input
                  type="password"
                  id="password"
                  placeholder="New password*"
                  required
                  onChange={passwordHandler}
                />
              </label>
            </div>
            <label htmlFor="passowrdComfirm">
              <input
                type="password"
                id="passowrdComfirm"
                placeholder="New passowrd Comfirm*"
                required
                onChange={passwordConfirmHandle}
              />
            </label>
          </div>
          <div className={styles["login-btn"]}>
            <button type="submit">Submit</button>
          </div>
        </div>
      </form>
    </section>
  );
};

export default UpdateMyPassword;
