import React, { useContext } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import AuthContext from "./store/auth-context";
import Home from "./home/pages/Home";
import Event from "./event/page/Event";
import EventDetailsPage from "./event/page/EventDetailsPage";
import About from "./about/page/About";
import Login from "./auth/Login";
import ForgetPassword from "./auth/ForgetPassword";
import ResetPassword from "./auth/ResetPassword";
import UpdateMyPassword from "./auth/UpdateMyPassword";
import SongsPage from "./song/page/SongsPage";
import PostsPage from "./post/page/PostsPage";
import UserProfile from "./user/page/UserProfile";
import FetchingNav from "./home/components/navigation/FetchingNav";
import ScrollToTop from "./store/ScrollToTop";
import Footer from "./home/components/sectionFooter/Footer";
import NewSong from "./user/components/newSong/NewSongs";
import PostForm from "./user/components/newPost/PostForm";
import FetchingUpdatePost from "./post/components/post/updatePost/FetchingUpdatePost";
import FetchingUpdateSong from "./song/components/updateSong/FetchingUpdateSong";
import Signup from "./auth/Signup";
import Konto from "./user/components/konto/Konto";
import AddEvent from "./event/page/AddEvent";
import DeleteMe from "./auth/DeleteMe";

function App() {
  const authCtx = useContext(AuthContext);
  const isLoggedIn = authCtx.isLoggedIn;

  return (
    <div>
      <FetchingNav />
      <ScrollToTop>
        <Switch>
          {/* {!isLoggedIn && <Redirect to="/hanf" />} */}
          <Route path="/" exact>
            <Redirect to="/hanf" />
          </Route>
          <Route path="/hanf" exact>
            <Home />
          </Route>
          <Route path="/hanf/:about">
            <About />
          </Route>
          <Route path="/events" exact>
            <Event />
          </Route>
          <Route path="/events/:eventDetails">
            <EventDetailsPage />
          </Route>
          <Route path="/eventsForm">
            <AddEvent />
          </Route>
          <Route path="/songs" exact>
            <SongsPage />
          </Route>
          {isLoggedIn && (
            <Route path="/updateSong/:updateSongId">
              {isLoggedIn && <FetchingUpdateSong />}
              {!isLoggedIn && <Redirect to="/hanf" />}
            </Route>
          )}
          <Route path="/posts" exact>
            <PostsPage />
          </Route>
          <Route path="/updatePost/:updatePostId">
            {isLoggedIn && <FetchingUpdatePost />}
            {!isLoggedIn && <Redirect to="/hanf" />}
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/profile" exact>
            {isLoggedIn && <UserProfile />}
            {!isLoggedIn && <Redirect to="/hanf" />}
          </Route>
          <Route path="/profile/:profileId" exact>
            <UserProfile />
          </Route>
          <Route path="/newSong" exact>
            {isLoggedIn && <NewSong />}
            {!isLoggedIn && <Redirect to="/hanf" />}
          </Route>
          <Route path="/postForm" exact>
            {isLoggedIn && <PostForm />}
            {!isLoggedIn && <Redirect to="/hanf" />}
          </Route>
          <Route path="/login" exact>
            <Login />
          </Route>
          <Route path="/forgotPassword" exact>
            <ForgetPassword />
          </Route>
          <Route path="/resetPassword/:token">
            <ResetPassword />
          </Route>
          <Route path="/updateMyPassword" exact>
            {isLoggedIn && <UpdateMyPassword />}
            {!isLoggedIn && <Redirect to="/hanf" />}
          </Route>
          <Route path="/updateMyPassword" exact>
            {isLoggedIn && <DeleteMe />}
            {!isLoggedIn && <Redirect to="/hanf" />}
          </Route>
          <Route path="/registrieren" exact>
            <Signup />
          </Route>
          <Route path="/konto" exact>
            {isLoggedIn && <Konto />}
            {!isLoggedIn && <Redirect to="/hanf" />}
          </Route>
        </Switch>
      </ScrollToTop>
      <Footer />
    </div>
  );
}

export default App;
