import React, { Fragment, useRef, useState, useEffect } from "react";
import "react-h5-audio-player/lib/styles.css";
import { IoMdPlay, IoMdPause } from "react-icons/io";
import { styled, Slider, Typography } from "@mui/material";
import styles from "./TrendingSongs.module.css";
import { Link } from "react-router-dom";

const PSlider = styled(Slider)(({ theme, ...props }) => ({
  color: "lime",
  width: "180px",
  display: "flex",
  marginBottom: "1px",
  flexDirection: "column",
  justifyContent: "center",
  marginLeft: "40px",
  // alignItems: "flex-start",
  // height: 2,

  "& .MuiSlider-thumb": {
    width: "10px",
    height: "10px",
    display: props.thumbless ? "none" : "block",
  },
}));

const TrendingSongs = (props) => {
  const audioPlayer = useRef(
    new Audio(`https://hanf.herokuapp.com/${props.audio}`)
  );
  const clickRef = useRef();
  const [isPlaying, setIsPlaying] = useState(false);
  const [elapsed, setElapsed] = useState(0);
  // const [timeRemaining, setTimeRemaining] = useState(0);
  const [duration, setDuration] = useState(0);
  // const [songIdToPlay, setSongIdToPlay] = useState();

  // useEffect(() => {
  //   if (isPlaying) {
  //     setSongIdToPlay(`http://127.0.0.1:8000/${props.audio}`);
  //   }
  // }, [isPlaying]);

  useEffect(() => {
    if (isPlaying) {
      setInterval(() => {
        const _duration = Math.floor(audioPlayer?.current?.duration);
        const _elapsed = Math.floor(audioPlayer?.current?.currentTime);

        setDuration(_duration);
        setElapsed(_elapsed);
      }, 100);
    }
  }, [isPlaying]);

  function formatTime(time) {
    if (time && !isNaN(time)) {
      const minutes =
        Math.floor(time / 60) < 10
          ? `0${Math.floor(time / 60)}`
          : Math.floor(time / 60);
      const seconds =
        Math.floor(time % 60) < 10
          ? `0${Math.floor(time % 60)}`
          : Math.floor(time % 60);

      return `${minutes}:${seconds}`;
    }
    return "00:00";
  }

  const checkWidth = (event) => {
    let width = clickRef.current.clientWidth;
    const offset = event.nativeEvent.offsetX;

    const divprogress = (offset / width) * 100;
    audioPlayer.current.currentTime =
      (divprogress / 30) * `https://hanf.herokuapp.com/${props.audio}`.length;
  };

  const togglePlay = () => {
    // setIsPlaying(songIdToPlay);
    if (!isPlaying) {
      audioPlayer.current.play();
      setIsPlaying(audioPlayer.current.play());
      // console.log(audioPlayer.currentTime);
    } else {
      audioPlayer.current.pause();
      setIsPlaying(audioPlayer.current.pause());
    }
  };

  return (
    <Fragment>
      <audio
        src={`https://hanf.herokuapp.com/${props.audio}`}
        ref={audioPlayer}
      />
      <div className={styles.card}>
        <div className={styles["song-img"]}>
          <div className={styles.image}>
            <img src={`https://hanf.herokuapp.com/${props.image}`} alt="img" />
          </div>
        </div>
        <div className={styles.audios}>
          {!isPlaying && (
            <IoMdPlay className={styles.audio} onClick={togglePlay} />
          )}
          {isPlaying && (
            <IoMdPause className={styles.audio} onClick={togglePlay} />
          )}
        </div>
        {isPlaying && (
          <PSlider
            onClick={checkWidth}
            ref={clickRef}
            sx={{ width: `${formatTime(duration).progress + "%"}` }}
            thumbless="true"
            value={elapsed}
            max={duration}
          />
        )}
        {isPlaying && (
          <Typography
            sx={{
              fontSize: "15px",
              color: "#c471ed",
              width: "0px",
              marginTop: "-27px",
              marginLeft: "12px",
            }}
          >
            {formatTime(elapsed)}
          </Typography>
        )}

        {isPlaying && (
          <Typography
            sx={{
              fontSize: "15px",
              color: "#c471ed",
              width: "0px",
              marginTop: "-23px",
              marginLeft: "250px",
            }}
          >
            {formatTime(duration)}
          </Typography>
        )}
        <Link className={styles["song-link"]} to="/songs">
          <div className={styles["song-art"]}>
            <h1>{props.musicArt}</h1>
          </div>

          <div className={styles["song-name"]}>
            <strong>{props.title}</strong>
          </div>

          <div className={styles["artist-name"]}>
            <Link
              className={styles["song-link"]}
              to={`/profile/${props.user?.id}`}
            >
              <h1>
                {props.user.length === 0
                  ? "unknown artist"
                  : props.user?.firstname}
                <span>
                  {props.user.length === 0
                    ? "unknown artist"
                    : props.user?.surname}
                </span>
              </h1>
            </Link>
          </div>
        </Link>
      </div>
    </Fragment>
  );
};

export default TrendingSongs;
