import React, { Fragment } from "react";
import HomeImg from "./../../../assets/Home-img.jpg";
import styles from "./HeaderImage.module.css";

const HeaderImage = (props) => {
  return (
    <Fragment>
      <div className={styles["image"]}>
        <div className={styles["main-image"]}>
          <img src={HomeImg} alt="" />
          {/* <h1>Start Sharing Music</h1> */}
        </div>
        <div className={styles["title"]}>
          <h1>Hamburg Artist Networking Forum</h1>
        </div>
      </div>
    </Fragment>
  );
};

export default HeaderImage;
