import React, { useContext } from "react";
import { Link } from "react-router-dom";
// import staff2 from "./../../assets/staff2.jpg";
import AboutMe from "./AboutMe";
import AuthContext from "../../store/auth-context";
import UserContents from "./UserContents";
import styles from "./UserHeader.module.css";

const UserHeader = (props) => {
  const authCtx = useContext(AuthContext);
  const isLoggedIn = authCtx.isLoggedIn;

  return (
    <div className={styles["artist-profile"]}>
      <div className={styles["artist-pic-name"]}>
        <div className={styles["artist-pic"]}>
          <img src={`https://hanf.herokuapp.com/${props.photo}`} alt="user" />
        </div>
        <div className={styles["artist-name"]}>
          <h3>
            Hallo, ich bin <span>{props.firstname}</span>. Herzlich Willkommen
            zu meinem Profil!
          </h3>
        </div>
      </div>

      <div className={styles["btns-add-song-post"]}>
        {isLoggedIn && props.id === authCtx.id && (
          <Link to="/newSong" className={styles["link"]}>
            <div className={styles["artist-btns-add"]}>
              <h1>Neuer Song</h1>
            </div>
          </Link>
        )}
        {isLoggedIn && props.id === authCtx.id && (
          <Link to="/postForm" className={styles["link"]}>
            <div className={styles["artist-btns-add"]}>
              <h1>Neuer Post</h1>
            </div>
          </Link>
        )}
      </div>
      <div className={styles["line"]}></div>
      <AboutMe aboutMe={props.aboutMe} myProjects={props.myProjects} />
      <UserContents id={props.id} />
    </div>
  );
};

export default UserHeader;
