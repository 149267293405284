// import React from "react";
import UpdateSong from "./UpdateSong";
// import styles from "./UpdateSong.module.css";

const UpdateSongList = (props) => {
  return (
    <section>
      <UpdateSong
        key={props.song._id}
        id={props.song._id}
        title={props.song.title}
        image={props.song.image}
        audio={props.song.audio}
      />
    </section>
  );
};

export default UpdateSongList;
