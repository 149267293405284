// import React from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import styles from "./Singup.module.css";
import { SiGnuprivacyguard } from "react-icons/si";
import ClipLoader from "react-spinners/ClipLoader";

const Registrieren = () => {
  const history = useHistory();
  const [enteredFirstname, setEnteredFirstname] = useState("");
  const [enteredSurname, setEnteredSurname] = useState("");
  const [enteredEmail, setEnteredEmail] = useState("");
  const [enteredPassword, setEnteredPassword] = useState("");
  const [enteredPasswordConfirm, setEnteredPasswordConfirm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const firstnameHandler = (event) => {
    setEnteredFirstname(event.target.value);
  };
  const surnameHandler = (event) => {
    setEnteredSurname(event.target.value);
  };

  const emailHandler = (event) => {
    setEnteredEmail(event.target.value);
  };

  const passwordHandler = (event) => {
    setEnteredPassword(event.target.value);
  };

  const passwordConfirmHandler = (event) => {
    setEnteredPasswordConfirm(event.target.value);
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const response = await fetch(
        "https://hanf.herokuapp.com/api/v1/users/signup",
        {
          method: "POST",
          body: JSON.stringify({
            firstname: enteredFirstname,
            surname: enteredSurname,
            email: enteredEmail,
            password: enteredPassword,
            passwordConfirm: enteredPasswordConfirm,
            role: "artist",
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      // const currentData = await data;
      // console.log(data.data);
      if (!response.ok) {
        throw new Error(data.message || "Could not Register.");
      }
      setIsLoading(false);
      history.replace("/login");
    } catch (erro) {
      setIsLoading(false);
      setError(erro.message);
    }
  };

  if (isLoading) {
    return (
      <div className={styles["loader-container"]}>
        <ClipLoader color={"#fff"} size={150} />
      </div>
    );
  }

  return (
    <section>
      <div className={styles["singup-header"]}>
        <SiGnuprivacyguard className={styles["singup-icon"]} />
        <div className={styles["singup-title"]}>
          <h2>Registrieren</h2>
        </div>
      </div>

      {error && (
        <div className={styles["errors"]}>
          <div className={styles["error"]}>
            <h3>{error}</h3>
          </div>
        </div>
      )}

      <form onSubmit={submitHandler} className={styles["singup-form"]}>
        <div className={styles["singup"]}>
          <label htmlFor="firstname">
            <input
              type="firstname"
              id="firstname"
              placeholder="Vorname*"
              required
              onChange={firstnameHandler}
            />
          </label>
          <label htmlFor="surname">
            <input
              type="surname"
              id="surname"
              placeholder="Nachname*"
              required
              onChange={surnameHandler}
            />
          </label>
        </div>

        <div className={styles["singup"]}>
          <label htmlFor="email"></label>
          <input
            type="email"
            id="email"
            placeholder="Email*"
            required
            onChange={emailHandler}
          />
        </div>
        <div className={styles["singup"]}>
          <label htmlFor="password"></label>
          <input
            type="password"
            id="password"
            placeholder="password*"
            required
            onChange={passwordHandler}
          />
        </div>
        <div className={styles["singup"]}>
          <label htmlFor="passwordConfirm"></label>
          <input
            type="password"
            id="passwordConfirm"
            placeholder="Password Confirm*"
            required
            onChange={passwordConfirmHandler}
          />
        </div>
        <div className={styles["singup-btn"]}>
          <button type="submit">Registrieren</button>
        </div>
      </form>
    </section>
  );
};

export default Registrieren;
