import { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import AuthContext from "../store/auth-context";
import { HiOutlineEmojiSad } from "react-icons/hi";
import styles from "./UpdateMyPassword.module.css";
import ClipLoader from "react-spinners/ClipLoader";

const DeleteMe = () => {
  const authCtx = useContext(AuthContext);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const deleteMeHandle = async (event) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        "https://hanf.herokuapp.com/api/v1/users/deleteMe",
        {
          method: "DELETE",
          headers: {
            Authorization: "Bearer " + authCtx.token,
          },
        }
      );
      setIsLoading(false);
      authCtx.logout();
      history.replace("/hanf");
    } catch (erro) {
      setIsLoading(false);
      setError(erro.message);
    }
  };

  if (isLoading) {
    return (
      <div className="loader-container">
        <ClipLoader color={"#fff"} size={150} />
      </div>
    );
  }

  return (
    <section>
      <div className={styles["login-header"]}>
        <HiOutlineEmojiSad className={styles["login-icon"]} />
        <div className={styles["login-title"]}>
          <h2>Delete my account</h2>
        </div>
      </div>
      {error && (
        <div className={styles["errors"]}>
          <div className={styles["error"]}>
            <h3>{error}</h3>
          </div>
        </div>
      )}

      <div>
        <button onClick={deleteMeHandle}>DELETE Me</button>
      </div>
    </section>
  );
};

export default DeleteMe;
