import React, { useEffect, useState } from "react";
import TopUsersList from "./TopUsersList";

const FetchingTopUsers = (props) => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    (async () => {
      const res = await fetch(
        "https://hanf.herokuapp.com/api/v1/users/top-users"
      );
      const data = await res.json();
      setUsers(data.data.users);
      // console.log(data);
    })();
  }, []);

  return (
    <React.Fragment>
      <TopUsersList users={users} />
    </React.Fragment>
  );
};

export default FetchingTopUsers;
