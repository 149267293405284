import React, { Fragment } from "react";
import { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { BsMusicNoteBeamed } from "react-icons/bs";
import AuthContext from "../../../store/auth-context";
import styles from "./UpdateSong.module.css";
import ClipLoader from "react-spinners/ClipLoader";

const Updatesong = (props) => {
  const authCtx = useContext(AuthContext);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [uploadSong, setUploadSong] = useState(
    `https://hanf.herokuapp.com/${props.audio}`
  );
  const [uploadImage, setUploadImage] = useState(
    `https://hanf.herokuapp.com/${props.image}`
  );
  const [enteredTitle, setEnteredTitle] = useState(props.title);

  const changeTitleHandel = (event) => {
    setEnteredTitle(event.target.value);
  };

  const audioUplaodHandler = (event) => {
    setUploadSong(event.target.files[0]);
  };
  const imageUplaodHandler = (event) => {
    setUploadImage(event.target.files[0]);
  };

  const submitFormHandler = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("title", enteredTitle);
      formData.append("audio", uploadSong);
      formData.append("image", uploadImage);

      const response = await fetch(
        `https://hanf.herokuapp.com/api/v1/arts/${props.id}`,
        {
          method: "PATCH",
          body: formData,
          headers: {
            Authorization: "Bearer " + authCtx.token,
          },
        }
      );

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Could not create song.");
      }
      setIsLoading(false);
      history.push("/songs");
    } catch (err) {
      setIsLoading(false);
      setError(err.message);
    }
  };

  if (isLoading) {
    return (
      <div className={styles["loader-container"]}>
        <ClipLoader color={"#fff"} size={150} />
      </div>
    );
  }

  return (
    <Fragment>
      <div className={styles["add-music-header"]}>
        <BsMusicNoteBeamed className={styles["add-music-icon"]} />
        <div className={styles["add-music-title"]}>
          <h2>Lied editieren</h2>
        </div>
      </div>
      {error && (
        <div className={styles["errors"]}>
          <div className={styles["error"]}>
            <h3>{error}</h3>
          </div>
        </div>
      )}
      <form className={styles["new-song-form"]} onSubmit={submitFormHandler}>
        <div className={styles["song-form-input"]}>
          <label htmlFor="song">Song title</label>
        </div>
        <div className={styles["input-form"]}>
          <input
            id="song"
            type="text"
            value={enteredTitle}
            onChange={changeTitleHandel}
          />
        </div>
        <div className={styles["song-from-Upload"]}>
          <div className={styles["song-form-input"]}>
            <label htmlFor="audio">Uplaod Audio</label>
          </div>
          <div className={styles["input-form"]}>
            <input
              type="file"
              name="audio"
              accept="audio/*"
              id="audio"
              multiple
              src={uploadSong}
              onChange={audioUplaodHandler}
            />
          </div>
        </div>

        <div className={styles["song-from-Upload"]}>
          <div className={styles["song-form-input"]}>
            <label htmlFor="image">Uplaod Image</label>
          </div>
          <div className={styles["input-form"]}>
            <input
              type="file"
              name="image"
              accept="image/*"
              id="image"
              multiple
              src={uploadImage}
              onChange={imageUplaodHandler}
            />
          </div>
        </div>

        <div className={styles.actions}>
          <button type="submit" className={styles["job-from-btn"]}>
            Add song
          </button>
        </div>
      </form>
    </Fragment>
  );
};

export default Updatesong;
