// import React from "react";
import { useState, useContext } from "react";
import AuthContext from "../../../store/auth-context";
import { useHistory } from "react-router-dom";
import styles from "./UpdateMe.module.css";
import updateProfileImageAtom from "../../../recoil/atom/updateProfileImageAtom";
import { useRecoilState } from "recoil";

const UpdateMe = (props) => {
  const authCtx = useContext(AuthContext);
  const history = useHistory();
  const [enteredFirstname, setEnteredFirstname] = useState(props.firstname);
  const [enteredSurname, setEnteredSurname] = useState(props.surname);
  const [enteredEmail, setEnteredEmail] = useState(props.email);
  const [uploadPhoto, setUploadPhoto] = useState(
    `https://hanf.herokuapp.com/${props.photo}`
  );
  const [aboutMe, setAboutMe] = useState(props.aboutMe);
  const [myProjects, setMyProjects] = useState(props.myProjects);
  const [error, setError] = useState("");
  const [, setImage] = useRecoilState(updateProfileImageAtom);

  const firstnameHandler = (event) => {
    setEnteredFirstname(event.target.value);
  };
  const surnameHandler = (event) => {
    setEnteredSurname(event.target.value);
  };

  const emailHandler = (event) => {
    setEnteredEmail(event.target.value);
  };

  const uploadPhotoHandler = (event) => {
    setUploadPhoto(event.target.files[0]);
    setImage(event.target.files[0]);
  };
  const myProjectsHandler = (event) => {
    setMyProjects(event.target.value);
  };
  const aboutMeHandler = (event) => {
    setAboutMe(event.target.value);
  };

  const submitHandler = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("firstname", enteredFirstname);
    formData.append("surname", enteredSurname);
    formData.append("email", enteredEmail);
    formData.append("photo", uploadPhoto);
    formData.append("aboutMe", aboutMe);
    formData.append("myProjects", myProjects);
    try {
      const response = await fetch(
        `https://hanf.herokuapp.com/api/v1/users/updateMe`,
        {
          method: "PATCH",
          body: formData,
          headers: {
            Authorization: "Bearer " + authCtx.token,
          },
        }
      );

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Could not create job.");
      }
      setImage(data);
      history.push("/profile");
    } catch (erro) {
      setError(erro.message);
      // console.log(erro.message);
    }
  };

  return (
    <section>
      <div className={styles["signup-header"]}>
        <div className={styles["signup-title"]}>
          <h2>UpdateMe</h2>
        </div>
      </div>
      {error && (
        <div className={styles["errors"]}>
          <div className={styles["error"]}>
            <h3>{error}</h3>
          </div>
        </div>
      )}

      <form onSubmit={submitHandler} className={styles["signup-form"]}>
        <div className={styles["signup"]}>
          <label htmlFor="firstname">Vorname</label>
          <input
            type="firstname"
            id="firstname"
            value={enteredFirstname}
            onChange={firstnameHandler}
          />
        </div>
        <div className={styles["signup"]}>
          <label htmlFor="surname">Nachname</label>
          <input
            type="surname"
            id="surname"
            value={enteredSurname}
            onChange={surnameHandler}
          />
        </div>
        <div className={styles["signup"]}>
          <label htmlFor="email">E-mail</label>
          <input
            type="email"
            id="email"
            value={enteredEmail}
            onChange={emailHandler}
          />
        </div>

        <div className={styles["signup"]}>
          <label htmlFor="photo">profil bild</label>

          <input
            type="file"
            id="photo"
            accept="image"
            src={uploadPhoto}
            onChange={uploadPhotoHandler}
          />
        </div>

        <div className={styles["signup"]}>
          <label htmlFor="aboutMe">
            Über mich
            <textarea
              placeholder="Describe yourself here..."
              rows="10"
              cols="52"
              type="text"
              id="aboutMe"
              value={aboutMe}
              onChange={aboutMeHandler}
            />
          </label>
        </div>

        <div className={styles["signup"]}>
          <label htmlFor="myProjects">
            my Projects
            <textarea
              placeholder="Describe your Projects here..."
              rows="10"
              cols="52"
              type="text"
              id="myProjects"
              value={myProjects}
              onChange={myProjectsHandler}
            />
          </label>
        </div>

        <div className={styles["submit-btn"]}>
          <button type="submit">UpdateMe</button>
        </div>
      </form>
    </section>
  );
};

export default UpdateMe;
