import { useContext } from "react";
import AuthContext from "../../../store/auth-context";
import { Link } from "react-router-dom";

import styles from "./EventHeader.module.css";
const EventHeader = (props) => {
  const authCtx = useContext(AuthContext);
  const isLoggedIn = authCtx.isLoggedIn;

  return (
    <div className={styles["event-header"]}>
      <div className={styles.card}>
        <div className={styles["left-side-light-1"]}></div>
        {/* <div className={styles["left-side-light-2"]}></div> */}
        <div className={styles["right-side-light-1"]}></div>
        <div className={styles["right-side-light-2"]}></div>
      </div>
      <div className={styles["event-background"]}>
        <div className={styles["event-title"]}>
          <h3>Our events</h3>
        </div>
        <div className={styles["event-img"]}>
          <h4>Events</h4>
        </div>

        <div className={styles["event-sub-title"]}>
          <h1>WIR BIETEN EINE BÜHNE FÜR NEWCOMERS</h1>
        </div>

        {isLoggedIn && authCtx.role === "admin" && (
          <Link to="/eventsForm">
            <button>Add Event</button>
          </Link>
        )}
      </div>
    </div>
  );
};

export default EventHeader;
