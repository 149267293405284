// import React from "react";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import styles from "./Singup.module.css";
import { SiGnuprivacyguard } from "react-icons/si";
import ClipLoader from "react-spinners/ClipLoader";

const ForgetPassword = () => {
  const history = useHistory();
  const [enteredEmail, setEnteredEmail] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [checkEmail, setCheckEmail] = useState("");

  const emailHandler = (event) => {
    setEnteredEmail(event.target.value);
  };

  const submitHandler = async (event) => {
    event.preventDefault();

    setIsLoading(true);
    setCheckEmail(false);
    try {
      const response = await fetch(
        "https://hanf.herokuapp.com/api/v1/users/forgotPassword",
        {
          method: "POST",
          body: JSON.stringify({
            email: enteredEmail,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || "Could not reset password.");
      }

      setIsLoading(false);
      setCheckEmail(true);
      // history.replace("/");
    } catch (erro) {
      setIsLoading(false);
      setError(erro.message);
    }
  };

  if (isLoading) {
    return (
      <div className={styles["loader-container"]}>
        <ClipLoader color={"#fff"} size={150} />
      </div>
    );
  }

  return (
    <section>
      <div className={styles["singup-header"]}>
        <SiGnuprivacyguard className={styles["singup-icon"]} />
        <div className={styles["singup-title"]}>
          <h2>Passwort vergessen</h2>
        </div>
      </div>

      {error && !checkEmail && (
        <div className={styles["errors"]}>
          <div className={styles["error"]}>
            <h3>{error}</h3>
          </div>
        </div>
      )}

      {checkEmail && (
        <div>
          <div className={styles["check-email"]}>
            <div className={styles["check"]}>
              <h3>
                We have send you an E-mail. Please open it withen 10 min to be
                able to make a new password
              </h3>
            </div>
          </div>
          <Link to="/">
            <div className={styles["go-home-btn"]}>
              <button>Go to home page</button>
            </div>
          </Link>
        </div>
      )}

      {!isLoading && !checkEmail && (
        <form onSubmit={submitHandler} className={styles["singup-form"]}>
          <div className={styles["singup"]}>
            <label htmlFor="email"></label>
            <input
              type="email"
              id="email"
              placeholder="Email*"
              required
              onChange={emailHandler}
            />
          </div>
          <div className={styles["singup-btn"]}>
            <button type="submit">Submit</button>
          </div>
        </form>
      )}
    </section>
  );
};

export default ForgetPassword;
