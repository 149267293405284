import React, { Fragment } from "react";
import "react-h5-audio-player/lib/styles.css";
import Header from "./../components/header/Header";
import OurClientsFeedback from "./../components/sectionOurClientsFeedback/OurClientsFeedback";
import HeaderInfo from "./../components/header/HeaderInfo";
import SectionAboutUs from "./../components/sectionAboutUs/SectionAboutUs";
import FetchingOurEvents from "./../components/sectionOurEvents/FetchingOurEvents";
import FetchingTrendingPost from "./../components/sectionPost/FetchingTrendingPost";
import FetchingTrendingSongs from "../components/sectionTrendingSongs/FetchingTrendingSongs";

const Home = (props) => {
  return (
    <Fragment>
      <Header />
      <HeaderInfo />
      <SectionAboutUs />
      <FetchingOurEvents />
      <FetchingTrendingSongs />
      <FetchingTrendingPost />
      <OurClientsFeedback />
    </Fragment>
  );
};

export default Home;
