import React, { Fragment } from "react";
import SongsHeader from "../components/SongsHeader";
import FetchingTopUsers from "../components/sectionTopUsers/FetchingTopUsers";
import FetchingSongs from "../components/FetchingSongs";

const SongsPage = (props) => {
  return (
    <Fragment>
      <SongsHeader />
      <FetchingTopUsers />
      <FetchingSongs />
    </Fragment>
  );
};

export default SongsPage;
