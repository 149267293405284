import React, { useState } from "react";
import FetchingSongsContents from "./songsContents/FetchingSongsContents";
import FetchingPosts from "./postsContents/FetchingPosts";
// import SongsContents from "./songsContents/SongsContents";
import styles from "./UserContents.module.css";

const UserContents = (props) => {
  const [showSongs, setShowSongs] = useState(false);
  const [showPosts, setShowPosts] = useState(true);

  const songsHandler = () => {
    setShowSongs(true);
    setShowPosts(false);
  };

  const postsHandler = () => {
    setShowPosts(true);
    setShowSongs(false);
  };

  return (
    <div className={styles["user-contents"]}>
      <div className={styles["user-contents-bar"]}>
        <div className={styles["btn-contents-posts"]} onClick={postsHandler}>
          <h1>Posts</h1>
        </div>
        <div className={styles["btn-contents-songs"]} onClick={songsHandler}>
          <h1>Songs</h1>
        </div>
      </div>
      {showSongs && (
        <div className={styles["contents-songs"]}>
          <FetchingSongsContents id={props.id} />
        </div>
      )}

      {showPosts && (
        <div>
          {showPosts && (
            <div className={styles["contents-songs"]}>
              <FetchingPosts />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default UserContents;
