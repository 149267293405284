import React, { Fragment } from "react";
import EventForm from "../components/header/EventForm";

const AddEvent = (props) => {
  return (
    <Fragment>
      <EventForm />
    </Fragment>
  );
};

export default AddEvent;
