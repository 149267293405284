import React, { useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import styles from "./MainNavigation.module.css";

const NavBurger = (props) => {
  return (
    <React.Fragment>
      <nav className={styles["hamburger-nav"]}>
        <ul className={styles["hamburger-nav-list"]}>
          <li>
            <NavLink
              activeClassName={styles.active}
              className={styles["hamburger-nav-link"]}
              to="/hanf"
            >
              HANF
            </NavLink>
          </li>

          <li>
            <NavLink
              activeClassName={styles.active}
              className={styles["hamburger-nav-link"]}
              to="/events"
            >
              Events
            </NavLink>
          </li>

          <li>
            <NavLink
              activeClassName={styles.active}
              className={styles["hamburger-nav-link"]}
              to="/songs"
            >
              Musik
            </NavLink>
          </li>

          <li>
            <NavLink className={styles["hamburger-nav-link"]} to="/posts">
              Posts
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName={styles.active}
              className={styles["hamburger-nav-link"]}
              to="/about"
            >
              Über uns
            </NavLink>
          </li>
        </ul>
      </nav>
    </React.Fragment>
  );
};

export default NavBurger;
