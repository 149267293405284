import React, { useState, useContext } from "react";
import styles from "./Posts.module.css";
import AuthContext from "./../../../store/auth-context";
import { AiOutlineComment, AiOutlineSetting } from "react-icons/ai";
import CommentsInput from "./CommenetsInput";
import Comments from "./Comments";

const Posts = (props) => {
  const authCtx = useContext(AuthContext);
  const [showComments, setShowComments] = useState(false);
  const [showPostSetting, setShowPostSetting] = useState(false);

  const showPostSettingHandler = () => {
    if (!showPostSetting) {
      setShowPostSetting(true);
    } else {
      setShowPostSetting(false);
    }
  };

  const showCommentsHandler = () => {
    if (!showComments) {
      setShowComments(true);
    } else {
      setShowComments(false);
    }
  };

  const deletePostHandler = async () => {
    const response = await fetch(
      `https://hanf.herokuapp.com/api/v1/posts/${props.id}`,
      {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + authCtx.token,
        },
      }
    );

    const data = await response;
    // console.log(data);
    if (!response.ok) {
      throw new Error(data.message || "Could not delete a comment.");
    }
    props.onDelete(props.id);
  };

  return (
    <div className={styles["posts"]}>
      <div className={styles["post-card"]}>
        <div className={styles["post-user-info"]}>
          <div className={styles["user-photo"]}>
            <img
              src={`https://hanf.herokuapp.com/${props.user[0]?.photo}`}
              alt="user"
            />
          </div>
          <div className={styles["username"]}>
            <h2>
              {props.user[0]?.firstname} {props.user[0]?.surname}
            </h2>
          </div>
          {authCtx.id === props.user[0]?.id && (
            <AiOutlineSetting
              className={styles["setting"]}
              onClick={showPostSettingHandler}
            />
          )}
          {showPostSetting && (
            <ul className={styles["setting-list"]}>
              <li
                className={styles["setting-list-link"]}
                onClick={deletePostHandler}
              >
                Delete
              </li>
            </ul>
          )}
        </div>
        <div className={styles["post-image"]}>
          <img src={`https://hanf.herokuapp.com/${props.image}`} alt="user" />
        </div>
        <div className={styles["post-text"]}>
          <p>{props.post}</p>
        </div>
        <div className={styles["post-features"]}>
          <div>
            <AiOutlineComment
              className={styles["post-icon"]}
              onClick={showCommentsHandler}
            />
          </div>
          <CommentsInput id={props.id} onAdd={props.onAddComment} />
        </div>

        {showComments &&
          props.comments.map((comment) => (
            <Comments
              key={comment._id}
              id={comment._id}
              user={comment.user}
              comment={comment.comment}
            />
          ))}

        {showComments && props.comments.length === 0 && (
          <div className={styles["no-post"]}>
            <h1>Keine Kommentare, du kannst einen neuen erstellen</h1>
          </div>
        )}
      </div>
    </div>
  );
};

export default Posts;
