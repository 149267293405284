// import React from "react";
import styles from "./OurClientsFeedback.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faQuoteLeft,
  // faArrowRightLong,
  // faArrowLeftLong,
} from "@fortawesome/free-solid-svg-icons";
import UserImage from "./../../../assets/user1.jpg";

const OurClientsFeedback = (props) => {
  return (
    <section>
      {/* <FontAwesomeIcon
        icon={faArrowRightLong}
        className={styles["arrow-right"]}
      />

      <FontAwesomeIcon
        icon={faArrowLeftLong}
        className={styles["arrow-left"]}
      /> */}
      <div className={styles.title}>
        <h3>Stimmen aus unserer Community</h3>
      </div>

      <div className={styles["sub-title"]}>
        <h1>Networken, Grenzen durchbrechen und groß rauskommen</h1>
      </div>

      <div className={styles["grid-2-cols"]}>
        {/* <FontAwesomeIcon
          icon={faArrowRightLong}
          className={styles["arrow-right"]}
        />

        <FontAwesomeIcon
          icon={faArrowLeftLong}
          className={styles["arrow-left"]}
        /> */}
        <p className={styles["feedback-username"]}>
          <strong className={styles.name}>Marcus</strong>
          <FontAwesomeIcon icon={faQuoteLeft} className={styles["icon"]} />
        </p>
        <p className={styles["feedback-text"]}>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Natus
          similique adipisci praesentium sit amet consectetur adipisicing elit.
          Natus similique adipisci praesentium similique adipisci praesentium
          sit amet praesentium sit amet consectetur adipisicing elit. Natus
          similique adipisci praesentium similique adipisci praesentium sit
          amet.
        </p>
        <img src={UserImage} alt="" className={styles.image} />
      </div>
      <div className={styles.line}></div>
    </section>
  );
};

export default OurClientsFeedback;
