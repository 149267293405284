// import React from "react";
import {
  TiSocialInstagram,
  TiSocialFacebook,
  TiSocialYoutube,
  TiSocialTwitter,
} from "react-icons/ti";
import styles from "./SocialMedia.module.css";

const SocialMedia = (props) => {
  return (
    <div className={styles.media}>
      <div className={styles.hanfs}>
        <p className={styles.hanf}>HANF</p>
      </div>
      <div className={styles["social-media"]}>
        <TiSocialFacebook className={styles["social-media-icon"]} />
        <TiSocialTwitter className={styles["social-media-icon"]} />
        <TiSocialInstagram className={styles["social-media-icon"]} />
        <TiSocialYoutube className={styles["social-media-icon"]} />
      </div>
      <div className={styles.copyright}>
        <p className={styles["footer-copyright"]}>
          Copyright © 2022 by HANF, Inc. All rights reserved.
        </p>
      </div>
    </div>
  );
};

export default SocialMedia;
