// import React from "react";
import About from "./../../../assets/sectionAboutUs.jpg";
import styles from "./AboutHeader.module.css";

const AboutHeader = (props) => {
  return (
    <div className={styles["about-header"]}>
      <div className={styles["card-about"]}>
        <div className={styles["left-side-light-1"]}></div>
        <div className={styles["left-side-light-2"]}></div>
        <div className={styles["right-side-light-1"]}></div>
        <div className={styles["right-side-light-2"]}></div>
      </div>
      <div className={styles["about-header-title"]}>
        <h3 className={styles["abouts"]}>
          Our dedicated team of creatives is bursting with talent, experience
          and passion for what we do.
        </h3>
      </div>

      <div className={styles["about-header-img"]}>
        <img
          src={About}
          alt="event"
          className={styles["about-header-background"]}
        />
      </div>
      {/* <h1 className={styles["event-sub-title"]}>
        WIR BIETEN EINE BÜHNE FÜR NEWCOMERS DURCH USERE EVENTS
      </h1> */}
    </div>
  );
};

export default AboutHeader;
