import React, { useState, useContext, Fragment } from "react";
import { useRecoilState } from "recoil";
import AuthContext from "./../../../store/auth-context";
import styles from "./Posts.module.css";
import updateCommentAtom from "../../../recoil/atom/updateCommentAtom";

const CommentsInput = (props) => {
  const authCtx = useContext(AuthContext);
  const [enteredComment, setEnteredCommnet] = useState("");
  const [, setComments] = useRecoilState(updateCommentAtom);

  const commentHandler = (event) => {
    setEnteredCommnet(event.target.value);
  };

  const submitFormHandler = async (event) => {
    event.preventDefault();

    const response = await fetch(
      `https://hanf.herokuapp.com/api/v1/posts/${props.id}/comments`,
      {
        method: "POST",
        body: JSON.stringify({ comment: enteredComment }),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authCtx.token,
        },
      }
    );
    setEnteredCommnet("");

    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message || "Could not create a comment.");
    }

    setComments(data);
  };

  return (
    <Fragment>
      <form onSubmit={submitFormHandler} className={styles["write-post-field"]}>
        <input
          type="text"
          value={enteredComment}
          placeholder="Schreib hier dein Kommnetare "
          onChange={commentHandler}
        />

        <button type="submit" className={styles["comment-from-btn"]}>
          Send
        </button>
      </form>
    </Fragment>
  );
};

export default CommentsInput;
