import React, { useEffect, useState } from "react";
import UpdatePostList from "./UpdatePostList";
import { useParams } from "react-router-dom";
import styles from "./UpdatePost.module.css";
import ClipLoader from "react-spinners/ClipLoader";

const FetchingUpdatePost = (props) => {
  const params = useParams();
  const { updatePostId } = params;
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    try {
      (async () => {
        const res = await fetch(
          `https://hanf.herokuapp.com/api/v1/posts/${updatePostId}`
        );
        const data = await res.json();
        setPosts(data.data.post);
        setIsLoading(false);
      })();
    } catch (err) {
      setIsLoading(false);
      setError(err.message);
    }
  }, [updatePostId]);

  if (isLoading) {
    return (
      <div className={styles["loader-container"]}>
        <ClipLoader color={"#fff"} size={150} />
      </div>
    );
  }
  return (
    <React.Fragment>
      <UpdatePostList posts={posts} />
      {/* {!isLoading && posts.length === 0 && !error && (
          <p>Found no Trending Posts</p>
        )} */}
      {/* {isLoading && <p>Loading...</p>}
        {!isLoading && error && <p>{error}</p>} */}
    </React.Fragment>
  );
};

export default FetchingUpdatePost;
