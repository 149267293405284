import React, { Fragment } from "react";
import { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { BsMusicNoteBeamed } from "react-icons/bs";
import AuthContext from "../../../../store/auth-context";
import styles from "./UpdatePost.module.css";
import ClipLoader from "react-spinners/ClipLoader";

const UpdatePost = (props) => {
  const authCtx = useContext(AuthContext);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [uploadImage, setUploadImage] = useState(
    `${process.env.REACT_APP_ASSET_URL}/${props.image}`
  );
  const [enteredPost, setEnteredPost] = useState(props.post);

  const changePostHandel = (event) => {
    setEnteredPost(event.target.value);
  };

  const imageUplaodHandler = (event) => {
    setUploadImage(event.target.files[0]);
  };

  const submitFormHandler = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("post", enteredPost);
      formData.append("image", uploadImage);

      const response = await fetch(
        `https://hanf.herokuapp.com/api/v1/posts/${props.id}`,
        {
          method: "PATCH",
          body: formData,
          headers: {
            Authorization: "Bearer " + authCtx.token,
          },
        }
      );

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Could not create Post.");
      }
      setIsLoading(false);
      history.push("/posts");
    } catch (err) {
      setIsLoading(false);
      setError(err.message);
    }
  };

  if (isLoading) {
    return (
      <div className={styles["loader-container"]}>
        <ClipLoader color={"#fff"} size={150} />
      </div>
    );
  }

  return (
    <Fragment>
      <div className={styles["update-post"]}>
        <div className={styles["add-music-header"]}>
          <BsMusicNoteBeamed className={styles["add-music-icon"]} />
          <div className={styles["add-music-title"]}>
            <h2>Post editieren</h2>
          </div>
        </div>
        {error && (
          <div className={styles["errors"]}>
            <div className={styles["error"]}>
              <h3>{error}</h3>
            </div>
          </div>
        )}
        <div className={styles["new-post"]}>
          <form
            className={styles["new-post-form"]}
            onSubmit={submitFormHandler}
          >
            <div className={styles["post-form-input"]}>
              <label htmlFor="post">Post</label>
            </div>
            <div className={styles["input-form"]}>
              <textarea
                rows="10"
                cols="70"
                id="post"
                type="text"
                value={enteredPost}
                onChange={changePostHandel}
              />
            </div>

            <div className={styles["post-from-Upload"]}>
              <label htmlFor="image">Uplaod Image</label>
              <input
                type="file"
                accept="image"
                id="image"
                src={uploadImage}
                onChange={imageUplaodHandler}
              />
            </div>
            <div className={styles.actions}>
              <button type="submit" className={styles["job-from-btn"]}>
                Add Post
              </button>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default UpdatePost;
