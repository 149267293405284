import React, { Fragment } from "react";
import { useRef, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { BsMusicNoteBeamed } from "react-icons/bs";
import AuthContext from "../../../store/auth-context";
import styles from "./NewSong.module.css";
import ClipLoader from "react-spinners/ClipLoader";

const NewSong = (props) => {
  const authCtx = useContext(AuthContext);
  const history = useHistory();
  const [uploadImage, setUploadImage] = useState();
  const [uploadAudio, setUploadaudio] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const titleInputRef = useRef();
  const songArtInputRef = useRef();

  const imageUplaodHandler = (event) => {
    setUploadImage(event.target.files[0]);
  };
  const audioUplaodHandler = (event) => {
    setUploadaudio(event.target.files[0]);
  };

  const submitFormHandler = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("title", titleInputRef.current.value);
      formData.append("musicArt", songArtInputRef.current.value);
      formData.append("image", uploadImage);
      formData.append("audio", uploadAudio);

      const response = await fetch(
        `https://hanf.herokuapp.com/api/v1/users/${authCtx.id}/arts`,
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: "Bearer " + authCtx.token,
          },
        }
      );

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Could not create job.");
      }
      setIsLoading(false);
      history.push("/songs");
    } catch (err) {
      setIsLoading(false);
      setError(err.message);
    }
  };

  if (isLoading) {
    return (
      <div className={styles["loader-container"]}>
        <ClipLoader color={"#fff"} size={150} />
      </div>
    );
  }

  return (
    <Fragment>
      <div className={styles["add-music-header"]}>
        <BsMusicNoteBeamed className={styles["add-music-icon"]} />
        <div className={styles["add-music-title"]}>
          <h2>Lied hochladen</h2>
        </div>
      </div>

      {error && (
        <div className={styles["errors"]}>
          <div className={styles["error"]}>
            <h3>{error}</h3>
          </div>
        </div>
      )}

      <form className={styles["new-song-form"]} onSubmit={submitFormHandler}>
        <div className={styles["song-form-input"]}>
          <div className={styles["label-form"]}>
            <label htmlFor="title">Lied Titel</label>
          </div>
          <div className={styles["input-form"]}>
            <input id="title" type="text" ref={titleInputRef} />
          </div>
        </div>
        <div className={styles["song-form-input"]}>
          <div className={styles["label-form"]}>
            <label htmlFor="Art">Genre</label>
          </div>
          <div className={styles["input-form"]}>
            <input id="Art" type="text" ref={songArtInputRef} />
          </div>
        </div>
        <div className={styles["song-from-Upload"]}>
          <div className={styles["label-form"]}>
            <label htmlFor="image">Bild hochladen</label>
          </div>
          <div className={styles["input-form-file"]}>
            <input
              type="file"
              name="image"
              accept="image/*"
              id="image"
              multiple
              onChange={imageUplaodHandler}
            />
          </div>
        </div>
        <div className={styles["song-from-Upload"]}>
          <div className={styles["label-form"]}>
            <label htmlFor="audio">Lied hochladen</label>
          </div>
          <div className={styles["input-form-file"]}>
            <input
              type="file"
              name="audio"
              accept="audio/*"
              id="audio"
              multiple
              onChange={audioUplaodHandler}
            />
          </div>
        </div>
        <div className={styles.actions}>
          <button type="submit">Hinzufügen</button>
        </div>
      </form>
    </Fragment>
  );
};

export default NewSong;
