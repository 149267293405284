import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import styles from "./TrendingSongs.module.css";

const TopUsers = (props) => {
  return (
    <Fragment>
      <div className={styles.card}>
        <div className={styles["song-img"]}>
          <div className={styles.image}>
            <img src={`https://hanf.herokuapp.com/${props.photo}`} alt="img" />
          </div>
        </div>
        <div className={styles["artist-name"]}>
          <Link className={styles["song-link"]} to={`/profile/${props.id}`}>
            <h1>
              {props.firstname}
              <span>{props.surname}</span>
            </h1>
          </Link>
        </div>
      </div>
    </Fragment>
  );
};

export default TopUsers;
