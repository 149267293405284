import React, { useState, useContext, useRef } from "react";
import { Link } from "react-router-dom";
import styles from "./Posts.module.css";
import AuthContext from "./../../../store/auth-context";
import { AiOutlineComment, AiOutlineSetting } from "react-icons/ai";
import CommentsInput from "./CommenetsInput";
import useOutsideClick from "../../../store/useOutsideClick";
import Comments from "./Comments";
// import openCommentsAtom from "../../../recoil/atom/openCommentsAtom";
// import { useRecoilValue } from "recoil";

const Posts = (props) => {
  // const openComments = useRecoilValue(openCommentsAtom);
  const authCtx = useContext(AuthContext);
  const ref = useRef();
  const [showComments, setShowComments] = useState(false);
  const [showPostSetting, setShowPostSetting] = useState(false);

  const showPostSettingHandler = () => {
    setShowPostSetting(!showPostSetting);
  };

  const showCommentsHandler = () => {
    setShowComments(!showComments);
  };

  useOutsideClick(ref, () => {
    if (showPostSetting) setShowPostSetting(false);

    if (showComments) setShowComments(false);
  });

  const deletePostHandler = async () => {
    const response = await fetch(
      `https://hanf.herokuapp.com/api/v1/posts/${props.id}`,
      {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + authCtx.token,
        },
      }
    );

    const data = await response;

    if (!response.ok) {
      throw new Error(data.message || "Could not delete a comment.");
    }

    props.onDelete(props.id);
  };

  return (
    <div className={styles["posts"]}>
      <div className={styles["post-card"]} ref={ref}>
        <div className={styles["post-user-info"]}>
          <div className={styles["user-photo"]}>
            <img
              src={`https://hanf.herokuapp.com/${props.user[0]?.photo}`}
              alt="user"
            />
          </div>
          <div className={styles["username"]}>
            <Link to={`/profile/${props.user[0]?.id}`} className={styles.link}>
              <h2>
                {props.user[0]?.firstname} {props.user[0]?.surname}
              </h2>
            </Link>
          </div>
          <div>
            {authCtx.id === props.user[0]?.id && (
              <AiOutlineSetting
                className={styles["setting"]}
                onClick={showPostSettingHandler}
              />
            )}
          </div>
          {showPostSetting && (
            <ul className={styles["setting-list"]}>
              <li
                className={styles["setting-list-link"]}
                onClick={deletePostHandler}
              >
                Delete
              </li>
              <Link to={`/updatePost/${props.id}`} className={styles.link}>
                <li className={styles["setting-list-link"]}>Edit</li>
              </Link>
            </ul>
          )}
        </div>
        <div className={styles["post-image"]}>
          <img src={`https://hanf.herokuapp.com/${props.image}`} alt="user" />
        </div>
        <div className={styles["post-text"]}>
          <p>{props.post}</p>
        </div>
        <div className={styles["post-features"]}>
          <div>
            <AiOutlineComment
              className={styles["post-icon"]}
              onClick={showCommentsHandler}
            />
          </div>

          <CommentsInput id={props.id} />
        </div>

        {showComments &&
          props.comments.map((comment) => (
            <Comments
              key={comment._id}
              id={comment._id}
              user={comment.user}
              comment={comment.comment}
              onDelete={props.onDeleteComment}
            />
          ))}

        {showComments && props.comments.length === 0 && (
          <div className={styles["no-post"]}>
            <h1>Keine Kommentare, du kannst einen neuen erstellen</h1>
          </div>
        )}
      </div>
    </div>
  );
};

export default Posts;
