// import React from "react";
import Event3 from "./../../../assets/event3.jpg";
import Event1 from "./../../../assets/event1.jpg";
import styles from "./FooterRecentEvents.module.css";

const FooterRecentEvents = (props) => {
  return (
    <div className={styles["recent-event"]}>
      <p className={styles["title-footer"]}>Upcoming Events</p>
      <div className={styles.wraper}>
        <div className={styles["recent-event-image"]}>
          <img src={Event3} alt="event" />
        </div>
        <div className={styles["recent-event-info"]}>
          <p className={styles.items}>Time To Have Fun</p>
          <p className={styles.item}>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit dolor sit
            amet consectetur.
          </p>
        </div>
      </div>

      <div className={styles.wraper}>
        <div className={styles["recent-event-image"]}>
          <img src={Event1} alt="event" />
        </div>
        <div className={styles["recent-event-info"]}>
          <p className={styles.items}>Time To Have Fun</p>
          <p className={styles.item}>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit dolor sit
            amet consectetur.
          </p>
        </div>
      </div>
    </div>
  );
};

export default FooterRecentEvents;
