import React, { Fragment } from "react";
import EventHeader from "./../components/header/EventHeader";
import FetchingEvent from "./../components/sectionEvents/FetchingEvent";

const Event = (props) => {
  return (
    <Fragment>
      <EventHeader />
      <FetchingEvent />
    </Fragment>
  );
};

export default Event;
