import React, { useEffect, useState, useContext } from "react";
import AuthContext from "./../../store/auth-context";
import { useParams } from "react-router-dom";
import UserList from "./UserList";
import styles from "./AboutMe.module.css";
import ClipLoader from "react-spinners/ClipLoader";

const FetchingUser = (props) => {
  const params = useParams();
  const { profileId } = params;
  const authCtx = useContext(AuthContext);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    setIsLoading(true);
    try {
      (async () => {
        const res = await fetch(
          `https://hanf.herokuapp.com/api/v1/users/${
            profileId ? profileId : authCtx.id
          }  `
        );
        const data = await res.json();

        if (!res.ok) {
          throw new Error(`This is an HTTP error: The status is ${res.status}`);
        }

        setUsers(data.data.user);
        setIsLoading(false);
      })();
    } catch (err) {
      setIsLoading(false);
      setError(err.message);
    }
  }, [authCtx.id, profileId]);

  if (isLoading) {
    return (
      <div className={styles["loader-container"]}>
        <ClipLoader color={"#fff"} size={150} />
      </div>
    );
  }
  return (
    <React.Fragment>
      {error && (
        <div className={styles["errors"]}>
          <div className={styles["error"]}>
            <h3>{error}</h3>
          </div>
        </div>
      )}
      <UserList users={users} />
    </React.Fragment>
  );
};

export default FetchingUser;
