// import React from "react";
import FooterRecentEvents from "./FooterRecentEvent";
import SocialMedia from "./SocialMedia";
import { GoLocation } from "react-icons/go";
import { AiOutlineMail } from "react-icons/ai";
import { MdOutlineLocalPhone } from "react-icons/md";
import styles from "./Footer.module.css";

const Footer = (props) => {
  return (
    <footer className={styles.footers}>
      <div className={styles.contact}>
        <p className={styles["title-footer"]}>Kontakt</p>

        <div className={styles["contact-item"]}>
          <GoLocation className={styles.icon} />
          <p className={styles.items}>Addresse</p>
          <p className={styles.item}>Straßestraße, Hamburg, 22222</p>
        </div>
        <div className={styles["contact-item"]}>
          <AiOutlineMail className={styles.icon} />
          <p className={styles.items}>E-mail</p>
          <p className={styles.item}>hanf@gmail.com</p>
        </div>
        <div className={styles["contact-item"]}>
          <MdOutlineLocalPhone className={styles.icon} />
          <p className={styles.items}>Kurz durchklingeln</p>
          <p className={styles.item}>+49 123 4567891</p>
        </div>
        {/* <div className={styles["footer-item"]}>Events</div>
        <div className={styles["footer-item"]}>Contact Us</li> */}
      </div>

      <FooterRecentEvents />

      <SocialMedia />
    </footer>
  );
};

export default Footer;
