// import React from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import styles from "./Singup.module.css";
import { SiGnuprivacyguard } from "react-icons/si";
import { useParams } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";

const Registrieren = () => {
  const params = useParams();
  const { token } = params;
  const history = useHistory();
  const [enteredPassword, setEnteredPassword] = useState();
  const [enteredPasswordConfirm, setEnteredPasswordConfirm] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const passwordHandler = (event) => {
    setEnteredPassword(event.target.value);
  };

  const passwordConfirmHandler = (event) => {
    setEnteredPasswordConfirm(event.target.value);
  };

  const submitHandler = async (event) => {
    event.preventDefault();

    setIsLoading(true);
    try {
      const response = await fetch(
        `https://hanf.herokuapp.com/api/v1/users/resetPassword/${token}`,
        {
          method: "PATCH",
          body: JSON.stringify({
            password: enteredPassword,
            passwordConfirm: enteredPasswordConfirm,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || "Could not reset Password.");
      }

      setIsLoading(false);
      history.replace("/login");
    } catch (err) {
      setIsLoading(false);
      setError(err.message);
    }
  };

  if (isLoading) {
    return (
      <div className={styles["loader-container"]}>
        <ClipLoader color={"#fff"} size={150} />
      </div>
    );
  }

  return (
    <section>
      <div className={styles["singup-header"]}>
        <SiGnuprivacyguard className={styles["singup-icon"]} />
        <div className={styles["singup-title"]}>
          <h2>Reset passwort</h2>
        </div>
      </div>

      {error && (
        <div className={styles["errors"]}>
          <div className={styles["error"]}>
            <h3>{error}</h3>
          </div>
        </div>
      )}

      <form onSubmit={submitHandler} className={styles["singup-form"]}>
        <div className={styles["singup"]}>
          <label htmlFor="password"></label>
          <input
            type="password"
            id="password"
            placeholder="password*"
            required
            onChange={passwordHandler}
          />
        </div>
        <div className={styles["singup"]}>
          <label htmlFor="passwordConfirm"></label>
          <input
            type="password"
            id="passwordConfirm"
            placeholder="Password Confirm*"
            required
            onChange={passwordConfirmHandler}
          />
        </div>
        <div className={styles["singup-btn"]}>
          <button type="submit">Submit</button>
        </div>
      </form>
    </section>
  );
};

export default Registrieren;
