import React, { Fragment } from "react";
import HeaderImage from "./HeaderImage";

const Header = (props) => {
  return (
    <Fragment>
      <HeaderImage />
    </Fragment>
  );
};

export default Header;
