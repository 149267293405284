import React, { Fragment } from "react";
import styles from "./Songs.module.css";
import Songs from "./Songs";

const SongsList = (props) => {
  return (
    <Fragment>
      <section className={styles["section-songs"]}>
        <div className={styles.title}>
          <h3>songs</h3>
        </div>
        {props.songs.map((song) => (
          <Songs
            key={song._id}
            id={song._id}
            user={song.user}
            image={song.image}
            audio={song.audio}
            musicArt={song.musicArt}
            title={song.title}
            onDelete={props.onDeleteSong}
          />
        ))}
      </section>
    </Fragment>
  );
};

export default SongsList;
