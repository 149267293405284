import React, { Fragment } from "react";
import EventDetail from "./EventDetail";
// import styles from "./BlogDetail.module.css";

const EventDetailList = (props) => {
  return (
    <Fragment>
      <EventDetail
        key={props.events._id}
        image={props.events.image}
        title={props.events.title}
        subTitle={props.events.subTitle}
        event={props.events.event}
        date={props.events.date}
        uhrZeit={props.events.uhrZeit}
        strasseUndHauseNummer={props.events.strasseUndHauseNummer}
        ort={props.events.ort}
        plz={props.events.plz}
      />
    </Fragment>
  );
};

export default EventDetailList;
