import React, { useEffect, useState } from "react";
import TrendingSongsList from "./TrendingSongsList";

const FetchingTrendingSongs = (props) => {
  const [songs, setSongs] = useState([]);

  useEffect(() => {
    (async () => {
      const res = await fetch(
        "https://hanf.herokuapp.com/api/v1/arts/trending-songs"
      );
      const data = await res.json();
      setSongs(data.data.arts);
      // console.log(data);
    })();
  }, []);

  return (
    <React.Fragment>
      <TrendingSongsList songs={songs} />
    </React.Fragment>
  );
};

export default FetchingTrendingSongs;
