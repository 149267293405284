import React, { Fragment } from "react";
import FetchingEventDetail from "../components/eventDetails/FetchingEventDetail";

const EventDetailsPage = (props) => {
  return (
    <Fragment>
      <FetchingEventDetail />
    </Fragment>
  );
};

export default EventDetailsPage;
