import React, { useEffect, useState } from "react";
import EventList from "./EventList";
import ClipLoader from "react-spinners/ClipLoader";

const FetchingEvent = (props) => {
  const [events, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    try {
      (async () => {
        const res = await fetch("https://hanf.herokuapp.com/api/v1/events");
        const data = await res.json();
        setPosts(data.data.events);
        // console.log(data);
        setIsLoading(false);
        setIsLoading(false);
      })();
    } catch (err) {
      setIsLoading(false);
    }
  }, []);

  if (isLoading) {
    return (
      <div>
        <ClipLoader color={"#fff"} size={150} />
      </div>
    );
  }

  return (
    <React.Fragment>
      <EventList events={events} />
      {/* {!isLoading && posts.length === 0 && !error && (
          <p>Found no Trending Posts</p>
        )} */}
      {/* {isLoading && <p>Loading...</p>}
        {!isLoading && error && <p>{error}</p>} */}
    </React.Fragment>
  );
};

export default FetchingEvent;
