import React, { useState } from "react";
import FetchingUpdateMe from "./FetchingUpdateMe";
import UpdateMyPassword from "../../../auth/UpdateMyPassword";
import styles from "./Konto.module.css";
import DeleteMe from "../../../auth/DeleteMe";

const Konto = (props) => {
  const [showKonto, setShowKonto] = useState(true);
  const [showUpdatePassword, setShowUpdatePassword] = useState(false);
  const [showDeleteMe, setShowDeleteMe] = useState(false);

  const showKontoHandler = () => {
    setShowKonto(true);
    setShowUpdatePassword(false);
    setShowDeleteMe(false);
  };

  const showUpdatePassowrdHandler = () => {
    setShowUpdatePassword(true);
    setShowKonto(false);
    setShowDeleteMe(false);
  };

  const showDeleteMeHandler = () => {
    setShowDeleteMe(true);
    setShowUpdatePassword(false);
    setShowKonto(false);
  };

  return (
    <div className={styles["konto-user"]}>
      <div className={styles["side-menu"]}>
        <div className={styles["konto"]} onClick={showKontoHandler}>
          <h1>Mein Konto</h1>
        </div>
        <div className={styles["konto"]} onClick={showUpdatePassowrdHandler}>
          <h1>Update passwort</h1>
        </div>
        <div className={styles["konto"]} onClick={showDeleteMeHandler}>
          <h1>Konto löschen</h1>
        </div>
      </div>
      <div className={styles["user"]}>
        {showKonto && (
          <div className={styles["update-user"]}>
            <FetchingUpdateMe />
          </div>
        )}
        {showUpdatePassword && (
          <div className={styles["update-password"]}>
            <UpdateMyPassword />
          </div>
        )}
        {showDeleteMe && (
          <div className={styles["update-user"]}>
            <DeleteMe />
          </div>
        )}
      </div>
    </div>
  );
};

export default Konto;
