import React, { Fragment } from "react";
import styles from "./TrendingSongs.module.css";
import TopUsers from "./TopUsers";

const TopUsersList = (props) => {
  return (
    <Fragment>
      <div className={styles["top-users"]}>
        <div className={styles.title}>
          <h3>Top artist</h3>
        </div>

        <div className={styles["sub-title"]}>
          <h1>Best of our artist</h1>
        </div>
        <div className={styles["grid-3-cols"]}>
          {props.users.map((user) => (
            <TopUsers
              key={user._id}
              id={user._id}
              firstname={user.firstname}
              surname={user.surname}
              photo={user.photo}
            />
          ))}
        </div>
        <div className={styles.line}></div>
      </div>
    </Fragment>
  );
};

export default TopUsersList;
