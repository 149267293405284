import React, { useEffect, useState } from "react";
import PostsList from "./PostsList";
import { useRecoilValue } from "recoil";
import updateCommentAtom from "../../../recoil/atom/updateCommentAtom";
import deleteCommentAtom from "../../../recoil/atom/deleteCommentAtom";
import Comments from "./Comments";

const FetchingPosts = (props) => {
  const updateComment = useRecoilValue(updateCommentAtom);
  const deleteComment = useRecoilValue(deleteCommentAtom);
  const [posts, setPosts] = useState([]);
  // const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // setIsLoading(true);
    try {
      (async () => {
        const res = await fetch("https://hanf.herokuapp.com/api/v1/posts");
        const data = await res.json();
        setPosts(data.data.posts);
        // setIsLoading(false);
      })();
    } catch (err) {
      // setIsLoading(false);
    }
  }, [updateComment, deleteComment]);

  const postDeletedHandler = (deletedPostId) => {
    setPosts((prevPosts) =>
      prevPosts.filter((posts) => posts.id !== deletedPostId)
    );
  };

  return (
    <React.Fragment>
      <PostsList posts={posts} onDeletePost={postDeletedHandler} />
    </React.Fragment>
  );
};

export default FetchingPosts;
