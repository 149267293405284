import React, { useState, useContext, useRef } from "react";
import { NavLink } from "react-router-dom";
import NavBurger from "./NavBurger";
import AuthContext from "../../../store/auth-context";
import { useHistory } from "react-router-dom";
import styles from "./MainNavigation.module.css";
import { GiHamburgerMenu } from "react-icons/gi";
import useOutsideClick from "../../../store/useOutsideClick";

const MainNavigation = (props) => {
  const history = useHistory();
  const [showList, setShowList] = useState(false);
  const [showHamburegerList, setShowHamburegerList] = useState(false);
  const ref = useRef();
  const authCtx = useContext(AuthContext);
  const isLoggedIn = authCtx.isLoggedIn;

  const logoutHandler = () => {
    authCtx.logout();
    history.replace("/");
    // window.location.reload();
  };

  const showListHandler = () => {
    setShowList(!showList);
  };

  const showHamburegerMeunHandle = () => {
    setShowHamburegerList(!showHamburegerList);
  };

  useOutsideClick(ref, () => {
    if (showHamburegerList) setShowHamburegerList(false);

    if (showList) setShowList(false);
  });

  return (
    <header className={styles.header} ref={ref}>
      <div className={styles["hamburger-menu-logo"]}>
        <GiHamburgerMenu
          className={styles["hamburger-menu"]}
          onClick={showHamburegerMeunHandle}
        />
      </div>

      {showHamburegerList && <NavBurger />}

      <nav className={styles["main-nav"]}>
        <ul className={styles["main-nav-list"]}>
          <li>
            <NavLink
              activeClassName={styles.active}
              className={styles["main-nav-link"]}
              to="/hanf"
            >
              HANF
            </NavLink>
          </li>

          <li>
            <NavLink
              activeClassName={styles.active}
              className={styles["main-nav-link"]}
              to="/events"
            >
              Events
            </NavLink>
          </li>

          <li>
            <NavLink
              activeClassName={styles.active}
              className={styles["main-nav-link"]}
              to="/songs"
            >
              Musik
            </NavLink>
          </li>

          <li>
            <NavLink className={styles["main-nav-link"]} to="/posts">
              Posts
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName={styles.active}
              className={styles["main-nav-link"]}
              to="/about"
            >
              Über uns
            </NavLink>
          </li>
        </ul>
      </nav>

      <div className={styles["logo"]}>
        <h2>HANF</h2>
      </div>

      {isLoggedIn && (
        <div className={styles["profil-btn"]}>
          <div className={styles["pofil-pic-btn"]}>
            <img
              src={`https://hanf.herokuapp.com/${props.photo}`}
              alt="user"
              onClick={showListHandler}
            />
          </div>
        </div>
      )}
      {showList && (
        <ul className={styles["user-profil-list"]} onClick={showListHandler}>
          <NavLink to="/profile" className={styles["user-profil-list-link"]}>
            Profil
          </NavLink>
          <NavLink to="/konto" className={styles["user-profil-list-link"]}>
            Konto
          </NavLink>
          <li
            className={styles["user-profil-list-link"]}
            onClick={logoutHandler}
          >
            Logout
          </li>
        </ul>
      )}

      <div className={styles["btn"]}>
        {!isLoggedIn && (
          <NavLink
            activeClassName={styles.active}
            className={styles["btn-signup"]}
            to="/registrieren"
          >
            Registrieren
          </NavLink>
        )}
        {!isLoggedIn && (
          <NavLink
            activeClassName={styles.active}
            className={styles["btn-login"]}
            to="/login"
          >
            Anmelden
          </NavLink>
        )}
      </div>
    </header>
  );
};

export default MainNavigation;
