// import React from "react";
// import staff1 from "./../../../assets/user3.jpg";
import staff2 from "./../../../assets/staff2.jpg";
import staff3 from "./../../../assets/user2.jpg";
import staff4 from "./../../../assets/staff3.jpg";
import styles from "./OurStaff.module.css";

const OurStaff = (props) => {
  return (
    // <section className={styles["section-our-staff"]}>
    <div className={styles["our-staffs"]}>
      <div className={styles["our-staffs-infos"]}>
        <h3 className={styles["our-staff-title"]}>Our Staff</h3>
        <h1 className={styles["our-staff-sub-title"]}>
          Wir bieten eine Bühne für Newcomer
        </h1>
        <p className={styles["our-staff-text"]}>
          s simply dummy text of the printing and typesetting industry. Lorem
          Ipsum has been the industry's standard dummy text ever since the
          1500s, when an unknown printer took a galley of type and scrambled it
          to make a type specimen book.
        </p>
      </div>
      <div className={styles["our-staffs-pics"]}>
        <div className={styles["our-staff-pic"]}>
          <img src={staff3} alt="event" />
          <h1>Maya</h1>
          <h3>PR Beraterin</h3>
        </div>
        <div className={styles["our-staff-pic"]}>
          <img src={staff2} alt="event" />
          <h1>Maya</h1>
          <h3>PR Beraterin</h3>
        </div>
        <div className={styles["our-staff-pic"]}>
          <img src={staff3} alt="event" />
          <h1>Maya</h1>
          <h3>PR Beraterin</h3>
        </div>
        <div className={styles["our-staff-pic"]}>
          <img src={staff4} alt="event" />
          <h1>Maya</h1>
          <h3>PR Beraterin</h3>
        </div>
      </div>
    </div>
    // </section>
  );
};

export default OurStaff;
