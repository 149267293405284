import React, { Fragment } from "react";
import { useRef, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import AuthContext from "../../../store/auth-context";
import { BsFilePost } from "react-icons/bs";
import styles from "./PostForm.module.css";
import ClipLoader from "react-spinners/ClipLoader";

const PostForm = (props) => {
  const authCtx = useContext(AuthContext);
  const history = useHistory();
  const [uploadImage, setUploadImage] = useState();
  const postInputRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const imageUplaodHandler = (event) => {
    setUploadImage(event.target.files[0]);
  };

  const submitFormHandler = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("post", postInputRef.current.value);
      formData.append("image", uploadImage);
      const response = await fetch(
        `https://hanf.herokuapp.com/api/v1/users/${authCtx.id}/posts`,
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: "Bearer " + authCtx.token,
          },
        }
      );

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Could not create Post.");
      }
      setIsLoading(false);
      history.push("/posts");
    } catch (err) {
      setIsLoading(false);
      setError(err.message);
    }
  };

  if (isLoading) {
    return (
      <div className={styles["loader-container"]}>
        <ClipLoader color={"#fff"} size={150} />
      </div>
    );
  }

  return (
    <Fragment>
      <div className={styles["add-music-header"]}>
        <BsFilePost className={styles["add-music-icon"]} />
        <div className={styles["add-music-title"]}>
          <h2>Post erstellen</h2>
        </div>
      </div>
      {error && (
        <div className={styles["errors"]}>
          <div className={styles["error"]}>
            <h3>{error}</h3>
          </div>
        </div>
      )}
      <form className={styles["new-post-form"]} onSubmit={submitFormHandler}>
        <div className={styles["post-form-input"]}>
          <div className={styles["label-form"]}>
            <label htmlFor="post">Post</label>
          </div>
          <div className={styles["input-form"]}>
            <textarea
              placeholder="Describe your Post here..."
              rows="10"
              cols="52"
              id="post"
              type="text"
              ref={postInputRef}
            />
          </div>
        </div>
        <div className={styles["post-from-Upload"]}>
          <div className={styles["label-form"]}>
            <label htmlFor="image">Uplaod Image</label>
          </div>
          <div className={styles["input-form-file"]}>
            <input
              type="file"
              name="image"
              accept="image/*"
              id="image"
              multiple
              onChange={imageUplaodHandler}
            />
          </div>
        </div>
        <div className={styles.actions}>
          <button type="submit" className={styles["job-from-btn"]}>
            Add Post
          </button>
        </div>
      </form>
    </Fragment>
  );
};

export default PostForm;
