import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import styles from "./Post.module.css";

const Post = (props) => {
  return (
    <Fragment>
      <Link to={`/posts`} className={styles.link}>
        <div className={styles.wrap}>
          <div className={styles.text}>
            <div className={styles["post-username"]}>
              <Link
                to={`/profile/${props.user[0]?.id}`}
                className={styles.link}
              >
                <h1>
                  {props.user.length === 0
                    ? "unknown artist"
                    : props.user[0]?.firstname}
                  <span>
                    {props.user.length === 0
                      ? "unknown artist"
                      : props.user[0]?.surname}
                  </span>
                </h1>
              </Link>
            </div>
            <div className={styles["user-image"]}>
              <img
                src={`https://hanf.herokuapp.com/${props.user[0]?.photo}`}
                alt="user"
              />
            </div>
            <p className={styles["post-description"]}>{props.post}</p>
          </div>
          <div className={styles.img}>
            <img src={`https://hanf.herokuapp.com/${props.image}`} alt="" />
          </div>
        </div>
      </Link>
    </Fragment>
  );
};

export default Post;
