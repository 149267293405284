import React, { Fragment } from "react";
import styles from "./BestOurFeatures.module.css";
import { IoIosColorPalette, IoIosPerson } from "react-icons/io";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";

const BestOurFeatures = (props) => {
  return (
    <Fragment>
      <div className={styles["section-best-our-feature"]}>
        <h3 className={styles["best-features-title"]}>Best Features</h3>

        <div className={styles["grid-4-cols"]}>
          <div className={styles["card-feature"]}>
            <IoIosPerson className={styles["best-feature-icon"]} />
            <p className={styles["best-feature-title"]}>
              <strong>Netzwerken</strong>
            </p>
            <p className={styles["best-feature-text"]}>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Natus
              similique adipisci praesentium sit amet consectetur ipsum dolor
              sit amet consectetur.
            </p>
          </div>

          <div className={styles["card-feature"]}>
            <IoIosColorPalette className={styles["best-feature-icon"]} />

            <p className={styles["best-feature-title"]}>
              <strong>Musik shoppen</strong>
            </p>
            <p className={styles["best-feature-text"]}>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Natus
              similique adipisci praesentium sit amet consectetur ipsum dolor
              sit amet consectetur.
            </p>
          </div>
          <div className={styles["card-feature"]}>
            <FontAwesomeIcon
              icon={faCalendar}
              className={styles["best-feature-icon"]}
            />
            <p className={styles["best-feature-title"]}>
              <strong>Events besuchen</strong>
            </p>
            <p className={styles["best-feature-text"]}>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Natus
              similique adipisci praesentium sit amet consectetur ipsum dolor
              sit amet consectetur.
            </p>
          </div>
          <div className={styles["card-feature"]}>
            <IoIosPerson className={styles["best-feature-icon"]} />
            <p className={styles["best-feature-title"]}>
              <strong>Netzwerken</strong>
            </p>
            <p className={styles["best-feature-text"]}>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Natus
              similique adipisci praesentium sit amet consectetur ipsum dolor
              sit amet consectetur.
            </p>
          </div>
        </div>
        {/* <div className={styles.line}></div> */}
      </div>
    </Fragment>
  );
};

export default BestOurFeatures;
