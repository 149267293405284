import UpdateMe from "./UpdateMe";

const UpdateMeList = (props) => {
  return (
    <section>
      <UpdateMe
        key={props.users._id}
        photo={props.users.photo}
        firstname={props.users.firstname}
        surname={props.users.surname}
        email={props.users.email}
        aboutMe={props.users.aboutMe}
        myProjects={props.users.myProjects}
      />
    </section>
  );
};

export default UpdateMeList;
