// import React from "react";
import Events from "./Events";
// import styles from "./Events.module.css";

const EventList = (props) => {
  return (
    <div>
      {props.events.map((event) => (
        <Events
          key={event._id}
          id={event._id}
          title={event.title}
          event={event.subTitle}
          image={event.image}
          date={event.date}
        />
      ))}
    </div>
  );
};

export default EventList;
