import React, { useEffect, useState, useContext } from "react";
import AuthContext from "../../../store/auth-context";
import UpdateMeList from "./UpdateMeList";
import styles from "./Konto.module.css";
import ClipLoader from "react-spinners/ClipLoader";

const FetchingKonto = (props) => {
  const authCtx = useContext(AuthContext);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    setIsLoading(true);
    try {
      (async () => {
        const res = await fetch(
          `https://hanf.herokuapp.com/api/v1/users/${authCtx.id}`
        );
        const data = await res.json();

        if (!res.ok) {
          throw new Error(`This is an HTTP error: The status is ${res.status}`);
        }

        setUsers(data.data.user);
        // console.log(data);
        setIsLoading(false);
      })();
    } catch (err) {
      setIsLoading(false);
      setError(err.message);
    }
  }, [authCtx.id]);

  if (isLoading) {
    return (
      <div className={styles["loader-container"]}>
        <ClipLoader color={"#fff"} size={150} />
      </div>
    );
  }

  return (
    <React.Fragment>
      <UpdateMeList users={users} />
    </React.Fragment>
  );
};

export default FetchingKonto;
