import React, { useEffect, useState, useContext } from "react";
import PostsList from "./PostsList";
import AuthContext from "./../../../store/auth-context";
import { useParams } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import updateCommentAtom from "../../../recoil/atom/updateCommentAtom";
import deleteCommentAtom from "../../../recoil/atom/deleteCommentAtom";
import { useRecoilValue } from "recoil";

const FetchingPosts = (props) => {
  const updateComment = useRecoilValue(updateCommentAtom);
  const deleteComment = useRecoilValue(deleteCommentAtom);
  const params = useParams();
  const { profileId } = params;
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const authCtx = useContext(AuthContext);

  useEffect(() => {
    setIsLoading(true);
    try {
      (async () => {
        const res = await fetch(
          `https://hanf.herokuapp.com/api/v1/users/${
            profileId ? profileId : authCtx.id
          }/posts`
        );
        const data = await res.json();
        setPosts(data.data.posts);
        setIsLoading(false);
      })();
    } catch (err) {
      setIsLoading(false);
    }
  }, [authCtx.id, profileId, updateComment, deleteComment]);

  const postDeletedHandler = (deletedPostId) => {
    setPosts((prevPosts) =>
      prevPosts.filter((posts) => posts.id !== deletedPostId)
    );
  };

  return (
    <React.Fragment>
      {/* {isLoading && <ClipLoader color={"#fff"} size={150} />} */}
      {!isLoading && (
        <PostsList posts={posts} onDeletePost={postDeletedHandler} />
      )}
      {!isLoading && posts.length === 0 && <h3>KEINE POSTS</h3>}
    </React.Fragment>
  );
};

export default FetchingPosts;
