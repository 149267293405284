import MainNavigation from "./MainNavigation";

const NavList = (props) => {
  //   console.log(users);
  return (
    <section>
      <MainNavigation key={props.users._id} photo={props.users.photo} />
    </section>
  );
};

export default NavList;
