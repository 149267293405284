import React, { Fragment } from "react";
import { useRef, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import AuthContext from "../../../store/auth-context";
import { BsFilePost } from "react-icons/bs";
import styles from "../../../user/components/newPost/PostForm.module.css";

const EventForm = (props) => {
  const history = useHistory();
  const authCtx = useContext(AuthContext);
  const [uploadImage, setUploadImage] = useState();
  //   const [uploadAudio, setUploadaudio] = useState();
  //   const titleInputRef = useRef();
  const titleInputRef = useRef();
  const subTitleInputRef = useRef();
  const eventInputRef = useRef();
  const eventAddressInputRef = useRef();
  const eventPlzInputRef = useRef();
  const telefonInputRef = useRef();
  const dateInputRef = useRef();
  const uhrZeitInputRef = useRef();

  const imageUplaodHandler = (event) => {
    setUploadImage(event.target.files[0]);
  };

  const submitFormHandler = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("title", titleInputRef.current.value);
    formData.append("subTitle", subTitleInputRef.current.value);
    formData.append("event", eventInputRef.current.value);
    formData.append(
      "strasseUndHauseNummer",
      eventAddressInputRef.current.value
    );
    formData.append("plz", eventPlzInputRef.current.value);
    formData.append("telefon", telefonInputRef.current.value);
    formData.append("date", dateInputRef.current.value);
    formData.append("uhrZeit", uhrZeitInputRef.current.value);
    formData.append("image", uploadImage);
    try {
      const response = await fetch("https://hanf.herokuapp.com/api/v1/events", {
        method: "POST",
        body: formData,
        headers: {
          Authorization: "Bearer " + authCtx.token,
        },
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Could not create Events.");
      }
      history.push("/events");
    } catch (err) {
      console.log(err.message);
    }
  };
  return (
    <Fragment>
      <div className={styles["add-music-header"]}>
        <BsFilePost className={styles["add-music-icon"]} />
        <div className={styles["add-music-title"]}>
          <h2>Event erstellen</h2>
        </div>
      </div>
      <form className={styles["new-post-form"]} onSubmit={submitFormHandler}>
        <div className={styles["post-form-input"]}>
          <div className={styles["label-form"]}>
            <label htmlFor="title">Title</label>
          </div>
          <div className={styles["input-form"]}>
            <input id="title" type="text" ref={titleInputRef} />
          </div>
        </div>

        <div className={styles["post-form-input"]}>
          <div className={styles["label-form"]}>
            <label htmlFor="subtitle">Sub Title</label>
          </div>
          <div className={styles["input-form"]}>
            <input id="subtitle" type="text" ref={subTitleInputRef} />
          </div>
        </div>

        <div className={styles["post-form-input"]}>
          <div className={styles["label-form"]}>
            <label htmlFor="event">event Info</label>
          </div>
          <div className={styles["input-form"]}>
            <input id="event" type="text" ref={eventInputRef} />
          </div>
        </div>

        <div className={styles["post-form-input"]}>
          <div className={styles["label-form"]}>
            <label htmlFor="address">address Info</label>
          </div>
          <div className={styles["input-form"]}>
            <input id="address" type="text" ref={eventAddressInputRef} />
          </div>
        </div>

        <div className={styles["post-form-input"]}>
          <div className={styles["label-form"]}>
            <label htmlFor="plz">plz</label>
          </div>
          <div className={styles["input-form"]}>
            <input id="plz" type="text" ref={eventPlzInputRef} />
          </div>
        </div>

        <div className={styles["post-form-input"]}>
          <div className={styles["label-form"]}>
            <label htmlFor="telefon">telefon</label>
          </div>
          <div className={styles["input-form"]}>
            <input id="telefon" type="text" ref={telefonInputRef} />
          </div>
        </div>

        <div className={styles["post-form-input"]}>
          <div className={styles["label-form"]}>
            <label htmlFor="date">date</label>
          </div>
          <div className={styles["input-form"]}>
            <input id="date" type="text" ref={dateInputRef} />
          </div>
        </div>

        <div className={styles["post-form-input"]}>
          <div className={styles["label-form"]}>
            <label htmlFor="zeit">zeit</label>
          </div>
          <div className={styles["input-form"]}>
            <input id="zeit" type="text" ref={uhrZeitInputRef} />
          </div>
        </div>
        <div className={styles["post-from-Upload"]}>
          <div className={styles["label-form"]}>
            <label htmlFor="image">Uplaod Image</label>
          </div>
          <div className={styles["input-form-file"]}>
            <input
              type="file"
              name="image"
              accept="image/*"
              id="image"
              multiple
              onChange={imageUplaodHandler}
            />
          </div>
        </div>
        <div className={styles.actions}>
          <button type="submit" className={styles["job-from-btn"]}>
            Add Post
          </button>
        </div>
      </form>
    </Fragment>
  );
};

export default EventForm;
