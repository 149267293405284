import React, { useState, useContext, Fragment } from "react";
import { useRecoilState } from "recoil";
import AuthContext from "./../../../store/auth-context";
import updateCommentAtom from "../../../recoil/atom/updateCommentAtom";
// import openCommentsAtom from "../../../recoil/atom/openCommentsAtom";
import styles from "./Posts.module.css";
import { Link } from "react-router-dom";

const CommentsInput = (props) => {
  const authCtx = useContext(AuthContext);
  const [enteredComment, setEnteredCommnet] = useState("");
  const [, setComments] = useRecoilState(updateCommentAtom);
  // const [, setOpen] = useRecoilState(openCommentsAtom);
  // const [, set] = useRecoilState(openCommentsAtom);
  const [error, setError] = useState();

  const commentHandler = (event) => {
    setEnteredCommnet(event.target.value);
  };

  const submitFormHandler = async (event) => {
    event.preventDefault();
    if (!authCtx.isLoggedIn) {
      setError("please login");
    }

    try {
      const response = await fetch(
        `https://hanf.herokuapp.com/api/v1/posts/${props.id}/comments`,
        {
          method: "POST",
          body: JSON.stringify({ comment: enteredComment }),
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + authCtx.token,
          },
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || "Could not create a comment.");
      }
      setEnteredCommnet("");
      setComments(data);
      // setOpen(true);
    } catch (err) {
      // setError(err.message);
    }
  };

  return (
    <Fragment>
      {!error && (
        <form
          onSubmit={submitFormHandler}
          className={styles["write-post-field"]}
        >
          <input
            type="text"
            value={enteredComment}
            placeholder="Schreib hier deine Kommnetare "
            onChange={commentHandler}
          />

          <button type="submit" className={styles["comment-from-btn"]}>
            Send
          </button>
        </form>
      )}

      {error && (
        <Link to="/login" className={styles["link"]}>
          <div className={styles["you-are-not-login"]}>
            <h1>{error}</h1>
          </div>
        </Link>
      )}
    </Fragment>
  );
};

export default CommentsInput;
