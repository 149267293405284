// import React from "react";
import styles from "./SectionAboutUs.module.css";
import sectionAboutUs from "./../../../assets/sectionAboutUs.jpg";
import { Link } from "react-router-dom";

const SectionAboutUs = () => {
  return (
    <section className={styles.about}>
      <div className={styles["grid-2-cols"]}>
        <div className={styles["features-title"]}>
          <h2>HANF</h2>
        </div>
        <div className={styles["features-sub-title"]}>
          <h3>Unsere Vision</h3>
        </div>
        <div className={styles["features-text"]}>
          <p>
            Zusammen wollen wir Newcomer vernetzen und Live-Musik
            revolutionieren. Ästhetische Abende, perfekte Tonqualität und vor
            allem: Connecten! Bei uns dreht sich alles um Networking, deine
            Industry-Ties oder einfach nur darum, einen tollen Abend mit anderen
            Musikbegeisterten zu verbringen. Dazu gibt es sehr viel Platz für
            Newcomer, Vertreter aus der Branche bei jedem Event und keinen
            Backstage. Du hast richtig gelesen: keinen Backstage-Bereich, damit
            auch vor und nach dem Act alle auf einer Augenhöhe sind!
          </p>
        </div>
        <Link to="/hanf/about" className={styles.button}>
          Mehr lesen ...
        </Link>

        <img src={sectionAboutUs} alt="" className={styles.image} />
      </div>
      <div className={styles.line}></div>
    </section>
  );
};
export default SectionAboutUs;
