import React, { Fragment } from "react";
import styles from "./OurEventsItem.module.css";
import { BsCalendarCheck } from "react-icons/bs";
import { Link } from "react-router-dom";

const OurEventsItem = (props) => {
  const date = props.date.toLocaleString("en-US", {
    month: "long",
    day: "2-digit",
  });
  return (
    <Fragment>
      <Link to={`/events/${props.id}`} className={styles.link}>
        <div className={styles.card}>
          <div className={styles["post-image"]}>
            <img
              src={`https://hanf.herokuapp.com/${props.image}`}
              alt="event"
            />
          </div>
          <div className={styles["event-title"]}>
            <h1>{props.title}</h1>
          </div>

          <div className={styles["event-description"]}>
            <p>{props.subTitle}</p>
          </div>

          <li className={styles["event-date"]}>
            <BsCalendarCheck className={styles["icon"]} />
            <span>{date}</span>
          </li>
        </div>
      </Link>
    </Fragment>
  );
};

export default OurEventsItem;
