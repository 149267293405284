// import React from "react";
import styles from "./WhoWeAre.module.css";

const WhoWeAre = (props) => {
  return (
    <section className={styles["section-who-we-are"]}>
      <div className={styles["who-we-are-card"]}>
        <h4 className={styles["who-we-are"]}>WER WIR SIND?</h4>
        <p className={styles["who-we-are-text"]}>
          s simply dummy text of the printing and typesetting industry. Lorem
          Ipsum has been the industry's standard dummy text ever since the
          1500s, when an unknown printer took a galley of type and scrambled it
          to make a type specimen book s simply dummy text of the printing and
          typesetting industry. Lorem Ipsum has been the industry's standard
          dummy text ever since the 1500s, when an unknown printer standard
          dummy text ever since the 1500s, when an unknown printer took a galley
          of type and scrambled it to make a type specimen book.
        </p>
      </div>
    </section>
  );
};

export default WhoWeAre;
