import React, { useEffect, useState } from "react";
import UpdateSongList from "./UpdateSongList";
import { useParams } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import styles from "./UpdateSong.module.css";

const FetchingUpdateSong = (props) => {
  const params = useParams();
  const { updateSongId } = params;
  const [song, setSong] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // const [error, setError] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    try {
      (async () => {
        const res = await fetch(
          `https://hanf.herokuapp.com/api/v1/arts/${updateSongId}`
        );
        const data = await res.json();
        setSong(data.data.art);
        setIsLoading(false);
      })();
    } catch (err) {
      setIsLoading(false);
      // setError(err.message);
    }
  }, [updateSongId]);

  if (isLoading) {
    return (
      <div className={styles["loader-container"]}>
        <ClipLoader color={"#fff"} size={150} />
      </div>
    );
  }

  return (
    <React.Fragment>
      <UpdateSongList song={song} />
    </React.Fragment>
  );
};

export default FetchingUpdateSong;
