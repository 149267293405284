import React, { Fragment } from "react";
import FetchingPosts from "../components/post/FetchingPosts";
import PostHeader from "../components/post/header/PostHeader";

const PostsPage = (props) => {
  return (
    <Fragment>
      <PostHeader />
      <FetchingPosts />
    </Fragment>
  );
};

export default PostsPage;
