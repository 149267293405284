import React, {
  Fragment,
  useState,
  useEffect,
  useRef,
  useContext,
} from "react";
import { AiOutlineSetting } from "react-icons/ai";
import { Link } from "react-router-dom";
import { styled, Slider, Typography } from "@mui/material";
import { IoMdPause, IoMdPlay } from "react-icons/io";
import { AiFillForward, AiFillBackward } from "react-icons/ai";
import AuthContext from "./../../../store/auth-context";
import styles from "./SongsContents.module.css";

const PSlider = styled(Slider)(({ theme, ...props }) => ({
  color: "lime",
  width: "350px",
  display: "flex",
  marginTop: "15px",
  flexDirection: "column",
  justifyContent: "center",
  marginLeft: "60px",
  // alignItems: "flex-start",
  // height: 2,

  "& .MuiSlider-thumb": {
    width: "10px",
    height: "10px",
    display: props.thumbless ? "none" : "block",
  },
}));

const SongsContents = (props) => {
  const authCtx = useContext(AuthContext);
  const audioPlayer = useRef(
    new Audio(`https://hanf.herokuapp.com/${props.audio}`)
  );
  const clickRef = useRef();
  const [isPlaying, setIsPlaying] = useState(false);
  const [elapsed, setElapsed] = useState(0);
  const [duration, setDuration] = useState(0);

  const [showPostSetting, setShowPostSetting] = useState(false);

  const showPostSettingHandler = () => {
    if (!showPostSetting) {
      setShowPostSetting(true);
    } else {
      setShowPostSetting(false);
    }
  };

  const deleteSongHandler = async () => {
    const response = await fetch(
      `https://hanf.herokuapp.com/api/v1/arts/${props.id}`,
      {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + authCtx.token,
        },
      }
    );

    const data = await response;
    // console.log(data);
    if (!response.ok) {
      throw new Error(data.message || "Could not delete a comment.");
    }
    props.onDelete(props.id);
  };

  useEffect(() => {
    if (isPlaying) {
      setInterval(() => {
        const _duration = Math.floor(audioPlayer?.current?.duration);
        const _elapsed = Math.floor(audioPlayer?.current?.currentTime);

        setDuration(_duration);
        setElapsed(_elapsed);
      }, 100);
    }
  }, [isPlaying]);

  function formatTime(time) {
    if (time && !isNaN(time)) {
      const minutes =
        Math.floor(time / 60) < 10
          ? `0${Math.floor(time / 60)}`
          : Math.floor(time / 60);
      const seconds =
        Math.floor(time % 60) < 10
          ? `0${Math.floor(time % 60)}`
          : Math.floor(time % 60);

      return `${minutes}:${seconds}`;
    }
    return "00:00";
  }

  const checkWidth = (event) => {
    let width = clickRef.current.clientWidth;
    const offset = event.nativeEvent.offsetX;

    const divprogress = (offset / width) * 100;
    audioPlayer.current.currentTime =
      (divprogress / 30) * `https://hanf.herokuapp.com/${props.audio}`.length;
  };

  const togglePlay = () => {
    if (!isPlaying) {
      setIsPlaying(audioPlayer.current.play());
    } else {
      setIsPlaying(audioPlayer.current.pause());
    }
  };

  const toggleForward = () => {
    audioPlayer.current.currentTime += 10;
  };

  const toggleBackward = () => {
    audioPlayer.current.currentTime -= 10;
  };

  return (
    <Fragment>
      <audio
        src={`https://hanf.herokuapp.com/${props.audio}`}
        ref={audioPlayer}
      />
      <div className={styles["song-audio-player"]}>
        <div className={styles["audio-player"]}>
          <div className={styles["song-pic"]}>
            <img
              src={`https://hanf.herokuapp.com/${props.image}`}
              alt="song pic"
            />
          </div>
          <div className={styles["song-username"]}>
            <h1>
              {props.user.length === 0
                ? "unknown artist"
                : props.user?.firstname}
              <span>
                {props.user.length === 0
                  ? "unknown artist"
                  : props.user?.surname}
              </span>
            </h1>
          </div>
          <div className={styles["music-art"]}>
            <h1>{props.musicArt}</h1>
          </div>
          <div className={styles["user-pic"]}>
            <img
              src={`https://hanf.herokuapp.com/${props.user.photo}`}
              alt="user pic"
            />
          </div>
          <div className={styles["song-title"]}>
            <Link
              className={styles["song-link"]}
              to={`/profile/${props.user?.id}`}
            >
              <h2>{props.title}</h2>
            </Link>
            {authCtx.id === props.user.id && (
              <AiOutlineSetting
                className={styles["setting"]}
                onClick={showPostSettingHandler}
              />
            )}
            {showPostSetting && (
              <ul className={styles["setting-list"]}>
                <li
                  className={styles["setting-list-link"]}
                  onClick={deleteSongHandler}
                >
                  Delete
                </li>
                <Link
                  to={`/updateSong/${props.id}`}
                  className={styles["song-link"]}
                >
                  <li className={styles["setting-list-link"]}>Edit</li>
                </Link>
              </ul>
            )}
          </div>
          <div className={styles["audio"]}>
            <AiFillBackward
              className={styles["backward"]}
              onClick={toggleBackward}
            />
            {!isPlaying && (
              <IoMdPlay className={styles["play"]} onClick={togglePlay} />
            )}
            {isPlaying && (
              <IoMdPause className={styles["play"]} onClick={togglePlay} />
            )}

            <AiFillForward
              className={styles["forward"]}
              onClick={toggleForward}
            />
          </div>

          <PSlider
            onClick={checkWidth}
            ref={clickRef}
            sx={{ width: `${formatTime(duration).progress + "%"}` }}
            thumbless="true"
            value={elapsed}
            max={duration}
          />
          <Typography
            sx={{
              fontSize: "15px",
              color: "#c471ed",
              width: "0px",
              marginTop: "-30.5px",
              marginLeft: "-465px",
            }}
          >
            {formatTime(elapsed)}
          </Typography>
          <Typography
            sx={{
              fontSize: "15px",
              color: "#c471ed",
              width: "0px",
              marginTop: "-101px",
              marginLeft: "440px",
            }}
          >
            {formatTime(duration)}
          </Typography>
        </div>
      </div>
    </Fragment>
  );
};

export default SongsContents;
