// import React from "react";
import styles from "./SongsHeader.module.css";

const SongsHeader = (props) => {
  return (
    <div className={styles["event-header"]}>
      <div className={styles.card}>
        <div className={styles["left-side-light-1"]}></div>
        <div className={styles["right-side-light-1"]}></div>
        <div className={styles["right-side-light-2"]}></div>
      </div>
      <div className={styles["event-background"]}>
        <div className={styles["event-title"]}>
          <h3>unsere songs</h3>
        </div>
        <div className={styles["event-img"]}>
          <h4>songs</h4>
        </div>

        {/* <div className={styles["event-sub-title"]}>
          <h1>WIR BIETEN EINE BÜHNE FÜR NEWCOMERS</h1>
        </div> */}
      </div>
    </div>
  );
};

export default SongsHeader;
