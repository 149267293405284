import React, { useEffect, useState } from "react";
import PostList from "./PostList";

const FetchingTrendingPost = (props) => {
  const [posts, setPosts] = useState([]);
  // const [isLoading, setIsLoading] = useState(false);
  // const [error, setError] = useState(null);

  useEffect(() => {
    (async () => {
      const res = await fetch(
        "https://hanf.herokuapp.com/api/v1/posts/trending-posts"
      );
      const data = await res.json();
      setPosts(data.data.posts);
      // console.log(data.data.posts);
    })();
  }, []);

  return (
    <React.Fragment>
      <PostList posts={posts} />
    </React.Fragment>
  );
};

export default FetchingTrendingPost;
