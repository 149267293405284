import React, { Fragment } from "react";
import FetchingUser from "../components/FetchingUser";
// import FetchingSongsContents from "../components/songsContents/FetchingSongsContents";
// import UserContents from "../components/UserContents";

const UserProfile = (props) => {
  return (
    <Fragment>
      <FetchingUser />
      {/* <UserContents /> */}
      {/* <FetchingSongsContents /> */}
    </Fragment>
  );
};

export default UserProfile;
