import React, { Fragment } from "react";
import styles from "./HeaderInfo.module.css";
import { IoIosColorPalette, IoIosPerson } from "react-icons/io";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
//import { faCompactDisc } from "@fortawesome/free-regular-svg-icons";

const HeaderInfo = (props) => {
  return (
    <Fragment>
      <section>
        {/* <h2>What makes us special</h2> */}
        <div className={styles["grid-3-cols"]}>
          <div className={styles["section"]}>
            <IoIosPerson className={styles["icon"]} />
            <p className={styles["features-title"]}>
              <strong>Netzwerken</strong>
            </p>
            <p className={styles["features-text"]}>
              Werde Teil unserer Community! Dazu bist du herzlich zu jedem
              unserer Events eingeladen, kannst aber auch von zu Hause
              teilhaben, indem du dich unserer Online-Community aus Musikern,
              Audio-Profis und Musikbegeisterten anschließt.
            </p>
          </div>

          <div className={styles["section"]}>
            <IoIosColorPalette className={styles["icon"]} />

            <p className={styles["features-title"]}>
              <strong>Musik shoppen</strong>
            </p>
            <p className={styles["features-text"]}>
              Neue Musik von Newcomern - das findest du bei uns. Dein Durst nach
              einem Gespräch mit deinem neuen Lieblings-Newcomer ist hiermit
              gestillt! Erfahre die Musikbranche wie sie leibt und lebt, ganz
              hautnah und teile deine eigenen Erfahrungen mit uns und der
              Community. Ready, Set, Connect!
            </p>
          </div>
          <div className={styles["section"]}>
            <FontAwesomeIcon icon={faCalendar} className={styles["icon"]} />
            <p className={styles["features-title"]}>
              <strong>Events besuchen</strong>
            </p>
            <p className={styles["features-text"]}>
              Du hast es satt, die ganze Zeit nur Texte zu lesen? Dann komm zu
              unserem nächsten Event! Auf dieser Website erfährst wann und wo
              das nächste Konzert oder Networking Event stattfindet.
            </p>
          </div>
        </div>
        <div className={styles.line}></div>
      </section>
    </Fragment>
  );
};

export default HeaderInfo;
