import React, { Fragment } from "react";
import SongsContents from "./SongsContents";

const SongsContentsList = (props) => {
  return (
    <Fragment>
      {props.songs.map((song) => (
        <SongsContents
          key={song._id}
          id={song._id}
          user={song.user}
          image={song.image}
          audio={song.audio}
          musicArt={song.musicArt}
          title={song.title}
          onDelete={props.onDeleteSong}
        />
      ))}
    </Fragment>
  );
};

export default SongsContentsList;
