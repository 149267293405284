import React, { useEffect, useState, useContext } from "react";
import AuthContext from "./../../../store/auth-context";
import SongsContentsList from "./SongsContentsList";
import { useParams } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";

const FetchingSongsContents = (props) => {
  const params = useParams();
  const { profileId } = params;
  const [songs, setSongs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const authCtx = useContext(AuthContext);

  useEffect(() => {
    setIsLoading(true);
    try {
      (async () => {
        const res = await fetch(
          `https://hanf.herokuapp.com/api/v1/users/${
            profileId ? profileId : authCtx.id
          }/arts`
        );
        const data = await res.json();
        setSongs(data.data.arts);
        setIsLoading(false);
      })();
    } catch (err) {
      setIsLoading(false);
      setError(err.message);
    }
  }, [authCtx.id, profileId]);

  const songDeletedHandler = (deleteSongId) => {
    setSongs((prevSong) =>
      prevSong.filter((songs) => songs._id !== deleteSongId)
    );
  };

  if (isLoading) {
    return (
      <div>
        <ClipLoader color={"#fff"} size={150} />
      </div>
    );
  }

  return (
    <React.Fragment>
      <SongsContentsList songs={songs} onDeleteSong={songDeletedHandler} />
      {songs.length === 0 && <h3>KEINE SONGS</h3>}
    </React.Fragment>
  );
};

export default FetchingSongsContents;
